import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import "./mowing-update-page.css";
import "./mowing-create-page.css";

import { archiveMowingReport, getMDReport, getLandowners, getPropertiesForLandowner} from "../apis/landsteward/accounts";
import { CreateMowingReport, terminateGeneratingReport } from "../apis/landsteward/mowing";

import closeIcon from "../images/close-popup-button.png";

export function MowingUpdatePage(props) {
    const params = useParams();
    const history = useHistory();
    const location = useLocation();

    /** CONSTANTS */
    const projectID = params.projectID;
    const [reportID, setReportID] = useState(params.mowingID);
    const project = props.searchState("projects", "projectID", projectID);
    const [reportInfo, setReportInfo] = useState({});
    const email = JSON.parse(sessionStorage.getItem('webState')).email;
    const reportNameMaxLength = 30;

    /** FLAGS */
    const [hazeDetected, setHazeDetected] = useState(false);
    const [cancelClicked, setCancelClicked] = useState(false);

    /** REPORT STATUSES:
     *   0 - report not started
     *   1 - ongoing search for hazy images
     *   2 - hazy images detected & ready for review
     *   3 - report generation continued
     *   4 - report failed
     */
    const [reportStatus, setReportStatus] = useState(0);

    /** COUNTDOWNS */
    const [countdownToExit, setCountdownToExit] = useState(10);
    let oldCountdownToExit = countdownToExit;

    /** FIELDS */
    const [reportName, setReportName] = useState("");
    const [reportToUpdateName, setReportToUpdateName] = useState("");
    const [version, setVersion] = useState(3);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [imageBands, setImageBands] = useState("TCI");
    const [resolution, setResolution] = useState("30");

    useEffect(() => {
        if (location.state && params.status != "3") {
            setReportToUpdateName(location.state.reportName);
            setReportName(`${location.state.reportName} Copy`);
            setVersion(location.state.version);
            setStartDate(getDate(location.state.startDate));
        }
    }, [])

    /** USER CLICKED PARTIALLY COMPLETED REPORT ON PROJECT PAGE */
    useEffect(() => {
        if (reportID) {
            getMDReport(reportID, 'mowing_detection').then(async (reportInfo) => {
                setReportInfo(reportInfo);
                setReportName(reportInfo.name);
                setVersion(reportInfo.version);
                setStartDate(getDate(reportInfo.start_date));
                setEndDate(getDate(reportInfo.end_date));
                getMDReport(reportInfo.id_to_update, 'mowing_detection').then(async (reportToUpdateInfo) => {
                    setReportToUpdateName(reportToUpdateInfo.name);
                })
                if (params.status == "2") {
                    setReportStatus(2);
                    setHazeDetected(true);
                } else if (params.status == "3") {
                    setReportStatus(3);
                }
            });
        }
    }, [])

    useEffect(() => {
        if (reportStatus == 3) {
            setHazeDetected(false);
            let excludedImages = location.state ? location.state.excludedImages : [];
            finalizeReport(reportInfo, excludedImages);
        }

    }, [reportStatus])

    useEffect(() => {
        if (countdownToExit == 0) history.push(`/project/${projectID}`);
    }, [countdownToExit])

    async function initializeReport(event) {
        if (checkAllFields()) {
            event.preventDefault();
            setReportStatus(1);
            
            const body = {
                'name': reportName,
                'projectID': projectID,
                'reportID': null,
                'oldReportID': location.state.reportID,
                'startDate': startDate,
                'endDate': endDate,
                'spatialResolution': 30,
                'cloudMask': true,
                'email': email,
                'update': true,
                'version': version,
                'checkForHaze': true,
            }

            let result = await CreateMowingReport(body);
            setReportID(result);

            if (result == null) {
                console.log('MOWING REPORT FAILED');
                setReportStatus(4);
            } else {
                setInterval(() => {
                    setCountdownToExit(--oldCountdownToExit)
                }, 1000);
            }
        }
    }

    async function finalizeReport(reportInfo, excludedImages) {
        const body = {
            'name': reportInfo.name,
            'projectID': projectID,
            'reportID': reportID,
            'oldReportID': reportInfo.id_to_update,
            'startDate': reportInfo.start_date,
            'endDate': reportInfo.end_date,
            'spatialResolution': reportInfo.spatial_res,
            'cloudMask': reportInfo.cloud_mask,
            'email': email,
            'update': true,
            'version': reportInfo.version,
            'checkForHaze': false,
            'allImages': reportInfo.all_images,
            'excludedImages': excludedImages
        }

        if (await CreateMowingReport(body) == null) {
            console.log('MOWING REPORT FAILED');
            setReportStatus(4);
        } else {
            setInterval(() => {
                setCountdownToExit(--oldCountdownToExit)
            }, 1000);
        }
    }

    function cancelReport() {
        if (reportStatus) {
            terminateGeneratingReport(reportID);
            archiveMowingReport(reportID);
        }

        history.push(`/project/${projectID}`);
    }

    /** HELPER FUNCTIONS BELOW */

    function getDate(dt) {
        let day = dt.slice(0, 2);
        let month = dt.slice(3, 5);
        let year = dt.slice(6, 10);

        return `${year}-${month}-${day}`;
    }

    function checkAllFields() {
        let missing = [];
        let currentDate = new Date().toJSON().slice(0,10);

        if (!reportName || !endDate) {
            if (!reportName) missing.push("report name");
            if (!endDate) missing.push("end date");
            alert("Missing required field(s): " + missing.join(", ") + " ");
        } else if (currentDate < endDate) {
            alert("Selected date is in the future.");
        } else if (startDate > endDate) {
            alert("End date must occur after start date.");
        } else {
            return true;
        }
    }

    return (
        <div className="page-margins">
            <div className="mowing-form">
                <h3 className="mowing-section-header">
                    Update {reportToUpdateName}
                </h3>
                <div className="mowing-form-project">
                    <div className="mowing-form-project-label">
                        Project:
                    </div> 
                    <div className="mowing-form-project-name">
                        {project ? project.name : ""}
                    </div>
                </div>
                <div className="mowing-form-input">
                    <div className="mowing-form-input-label">
                        Report Name:
                    </div>
                    <input
                        className="mowing-form-input-field"
                        type="text"
                        placeholder="My Mowing Report"
                        value={reportName}
                        onChange={(event) => setReportName(event.target.value)}
                        disabled={reportStatus == 1 || reportStatus == 2 || reportStatus == 3}
                        maxLength={reportNameMaxLength}
                    />
                    <div className="mowing-form-input-limit">
                        {reportName.length}/{reportNameMaxLength}
                    </div>
                </div>
                <div className="mowing-form-input">
                    <div className="mowing-form-input-label">
                        Version:
                    </div>
                    <select
                        className="mowing-form-input-field"
                        value={version}
                        onChange={(event) => setVersion(event.target.value)}
                        disabled={true}
                    >
                        <option value="1">1.0</option>
                        <option value="2">2.0</option>
                        <option value="3">3.0</option>
                    </select>
                </div>
                <div className="mowing-form-input">
                    <div className="mowing-form-input-label">
                        Start Date:
                    </div>
                    <input
                        className={`mowing-form-input-field${startDate ? "" : "-empty"}`}
                        type="date"
                        value={startDate}
                        onChange={(event) => setStartDate(event.target.value)}
                        disabled={true}
                    />
                </div>
                <div className="mowing-form-input">
                    <div className="mowing-form-input-label">
                        Updated End Date:
                    </div>
                    <input
                        className={`mowing-form-input-field${endDate ? "" : "-empty"}`}
                        type="date"
                        value={endDate}
                        onChange={(event) => setEndDate(event.target.value)}
                        disabled={reportStatus == 1 || reportStatus == 2 || reportStatus == 3}
                    />
                </div>
                <div className="mowing-form-input">
                    <div className="mowing-form-input-label">
                        Image Bands:
                    </div>
                    <select
                        className="mowing-form-input-field"
                        value={imageBands}
                        onChange={(event) => setImageBands(event.target.value)}
                        disabled={true}
                    >
                        <option value="TCI">TCI</option>
                    </select>
                </div>
                <div className="mowing-form-input">
                    <div className="mowing-form-input-label">
                        Resolution:
                    </div>
                    <select
                        className="mowing-form-input-field"
                        value={resolution}
                        onChange={(event) => setResolution(event.target.value)}
                        disabled={true}
                    >
                        <option value="30">30M</option>
                    </select>
                </div>
                <div className="mowing-form-note">
                    NOTE: Any new properties that have been added to this project will be included in this report update, 
                    but will only include data from after the date the report is updated
                </div>
                <div className="mowing-form-buttons">
                    <button 
                        className={`mowing-form-dark-button${(reportStatus == 1 || reportStatus == 2 || reportStatus == 3) ? "-disabled" : ""}`}
                        onClick={initializeReport}
                        disabled={reportStatus == 1 || reportStatus == 2 || reportStatus == 3}
                    >
                        {(reportStatus == 0 || reportStatus == 4) ?
                            "Generate Report" :
                            <span className="searching-animation">
                                {Array.from(reportStatus <= 2 ? "Searching..." : "Generating...").map((char, index) => {
                                    const style = {"animationDelay": index / 10 + "s"};
                                    return <span className="character" style={style}>{char}</span>
                                })}
                            </span>
                        }
                    </button>
                    <button 
                        className="mowing-form-light-button"
                        onClick={() => {setCancelClicked(true)}}
                    >
                        Cancel
                    </button>
                    {reportStatus != 0 && reportStatus != 2 &&
                        <div className="mowing-form-popup-notice" id="searching-notice">
                            <div className="mowing-form-popup-notice-text">
                                {reportStatus == 1 ?
                                    `Searching for hazy images. We’ll send you a notification when it’s done. Exiting the page in ${countdownToExit} seconds – do not refresh.` :
                                    reportStatus == 3 ?
                                        `Your report is now generating! We’ll send you a notification when it’s done. Exiting the page in ${countdownToExit} seconds – do not refresh.` :
                                        "Failed to generate report. You may try again."
                                }
                            </div>
                            <img 
                                className="mowing-form-popup-notice-button" 
                                src={closeIcon}
                                onClick={() => {document.getElementById("searching-notice").remove()}}
                            >
                            </img>
                        </div>
                    }
                </div>
            </div>

            {/* POPUPS */}

            {
                hazeDetected &&
                <div className="mowing-form-popup-prompt">
                    <div className="mowing-form-popup-prompt-text">
                        <div className="mowing-form-popup-prompt-title">
                            {Object.keys(JSON.parse(reportInfo['hazy_images'])).length} Hazy Images Found
                        </div>
                        <div className="mowing-form-popup-prompt-details">
                            Would you like to review these images? Reviewing them allows you to choose which images to exclude from your report.
                        </div>
                    </div>
                    <div className="mowing-form-popup-prompt-buttons">
                        <button 
                            className="mowing-form-popup-prompt-dark-button"
                            onClick={() => {history.push(`/project/${projectID}/${reportID}/update/mowing-confirm-haze`)}}
                        >
                            Review
                        </button>
                        <button 
                            className="mowing-form-popup-prompt-light-button"
                            onClick={() => {history.push(`/project/${projectID}/${reportID}/3/mowing-update`)}}
                        >
                            Ignore and Include
                        </button>
                    </div>
                </div>
            }
            {
                cancelClicked &&
                <div className="mowing-form-popup-prompt">
                    <div className="mowing-form-popup-prompt-text">
                        <div className="mowing-form-popup-prompt-title">
                            Cancel Report
                        </div>
                        <div className="mowing-form-popup-prompt-details">
                            Are you sure you want to cancel? You will lose all your progress.
                        </div>
                    </div>
                    <div className="mowing-form-popup-prompt-buttons">
                        <button 
                            className="mowing-form-popup-prompt-dark-button"
                            onClick={() => {cancelReport()}}
                        >
                            Yes, I’m Sure
                        </button>
                        <button 
                            className="mowing-form-popup-prompt-light-button"
                            onClick={() => {setCancelClicked(false)}}
                        >
                            Don’t Cancel
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}