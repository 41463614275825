import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { createReport } from '../apis/landsteward/accounts';
import { Dropdown } from '../components/modules/satellite-dropdown';

import { ImageContainer } from '../components/modules/image-container';

import MaryCreek from '../images/sample_region_image.png';

import './report-create-page.css';

/**
 *
 * @param {*} props
 *    <region>: selected region
 *    <project>: selected project
 *    <satelliteImage>:
 *    <satelliteImages>: selected satellite Image
 *    <addReport>:
 *    <changeSatelliteImage>:
 */
export function ReportCreationPage(props) {
  const params = useParams();

  /* Report Name */
  const [name, setName] = useState('');

  /* Report Form Screen (variable determines which screen */
  /* Defaults to type selection */
  const [screen, setScreen] = useState('ReportMetadata');

  /* All Report inputs by type */
  /*####################################################################### */
  /* Flood Risk */
  const [rainfallDepth, setRainfallDepth] = useState('33.8'); // in mm
  const [floodDamage, setFloodDamage] = useState('17'); // in $ / m^2
  const [pixelRes, setPixelRes] = useState('10');

  let [projectFailed, setProjectFailed] = useState(false);

  let [satelliteImage, setSatelliteImage] = useState(null);
  let [statusMessage, setStatusMessage] = useState('');

  /* Updates the current state using URL parameters */
  useEffect(async () => {
    let isCorrectState = await props.changeCurrentState({
      projectID: params.projectID,
    });
    if (!isCorrectState.project) {
      console.log('Failing Projects', isCorrectState);
      setProjectFailed(true);
    }
  }, []);

  /** Satellite Image Initial Satellite Image Selection */
  useEffect(async () => {
    console.log(props.satelliteImages);
    if (props.satelliteImages && props.satelliteImages.length > 0) {
      setSatelliteImage(props.satelliteImages[0]);
      console.log('changed satellite image to:', props.satelliteImages[0]);
    }
    console.log('satellite images: ', props.satelliteImages);
  }, []);

  /** CREATION BUTTON: changes status message and sends data to backend */
  async function triggerReportCreation() {
    if (props.project && satelliteImage) {
      setStatusMessage(
        'Your floodrisk report is being created...please check back later'
      );
      let reportInfo = await createReport(
        props.project.projectID,
        props.region.regionID,
        satelliteImage.imageID,
        name,
        '10'
      );

      if (reportInfo) {
        setStatusMessage('Your floodrisk report is ready!');
      } else {
        setStatusMessage('Oh Something went wrong!');
      }
    } else {
      console.error('USER HAS NOT SELECTED VALID SATELLITE IMAGE');
    }
  }

  /* Land Cover */
  // const [hasBuildings, setBuildings] = useState(true);

  let form;
  if (screen === 'ReportMetadata') {
    form = (
      <ReportMetadataSelection
        reportName={name}
        setReportName={setName}
        reportTypeList={[
          { title: 'Flood Risk', source: MaryCreek },
          // {title:'Carbon Sequestration', source: MaryCreek, click: () => setScreen('ReportMetadata')}
        ]}
        satelliteImage={
          satelliteImage
            ? satelliteImage
            : {
                png: MaryCreek,
                name: 'NoImageDataPleaseCreateSatelliteImageFirst',
                imageID: 'Mary Creek',
              }
        }
        setSatelliteImage={setSatelliteImage}
        satelliteImages={props.satelliteImages}
        changeSatelliteImage={(imageID) => {
          for (let i = 0; i < props.satelliteImages.length; i++) {
            if (props.satelliteImages[i].imageID === imageID) {
              setSatelliteImage(props.satelliteImages[i]);
              i = props.satelliteImages.length;
            }
          }
        }}
        rainfallDepth={rainfallDepth}
        setRainfallDepth={setRainfallDepth}
        floodDamage={floodDamage}
        setFloodDamage={setFloodDamage}
        pixelRes={pixelRes}
        setPixelRes={setPixelRes}
      />
    );
  } else {
    form = <div></div>;
    console.error('INVALID REPORT CREATION FORM');
  }

  /** ########## RENDER ################ */
  if (projectFailed) {
    return <Redirect to={`/project`} />;
  }
  return (
    <div className="report-creation">
      <div className="report-creation__title">
        <h1>Create New Ecosystem Service Report: Floodrisk</h1>
      </div>
      <p className="report-creation__project">
        <span className="name">Project:</span>{' '}
        {props.project ? props.project.name : ''}
      </p>
      <p className="report-creation__region">
        <span className="name">Region:</span>{' '}
        {''}
      </p>

      <label
        htmlFor="report-creation-name"
        className="report-creation__name-label"
      >
        {' '}
        <span className="name">Report Name:</span>
      </label>
      <input
        type="text"
        name="report-creation-name"
        value={props.reportName}
        onChange={(event) => props.setReportName(event.target.value)}
      />

      {form}

      <div className="report-new-button">
        {screen === 'ReportMetadata' && (
          <p className="status-text">{statusMessage}</p>
        )}
        {screen === 'ReportMetadata' && (
          <button
            style={{
              backgroundColor:
                statusMessage ===
                'Your flood risk is being created...please check back later'
                  ? 'gray'
                  : '#BAC7A7',
              cursor: statusMessage ? 'not-allowed' : 'pointer',
            }}
            onClick={triggerReportCreation}
          >
            {' '}
            Create Report
          </button>
        )}
      </div>
      <br />
    </div>
  );
}

/**
 *  Report Metadata Selection
 * @param {*} props
 *    <reportName>: name of the report to be created
 *    <setReportName>: report name variable's setter function
 *    <reportTypeList>: list of different reports that can be generated
 *      each report type will lead to a slightly different screen to save
 *      each contains a title and an image to display
 *    <satelliteImage>: the source of a satellite image that is to be used for
 *      the report generation
 *    <satelliteImages>: the list of available satellite Images
 */
export function ReportMetadataSelection(props) {
  return (
    <div className="report-creation__type-form">
      {/* Page Headings */}

      <form>
        <div className="report-creation__form-box">
          <p className="report-creation__form-title">
            Urban Flood Risk Mitigation Model Inputs
          </p>
          <label htmlFor="flood-risk-rainfall-depth">
            <span className="name">Rainfall Depth: (mm)</span>
          </label>
          <input
            type="text"
            name="flood-risk-rainfall-depth"
            value={props.rainfallDepth}
            onChange={(event) => props.setRainfallDepth(event.target.value)}
          />
          <br />

          <label htmlFor="flood-risk-flood-damage">
            <span className="name">
              Cost of built infrastructure due to flooding: ($/m^2)
            </span>
          </label>
          <input
            type="text"
            name="flood-risk-flood-damage"
            value={props.floodDamage}
            onChange={(event) => props.setFloodDamage(event.target.value)}
          />
        </div>
      </form>
      {/* Satellite Selection Image + Dropdown */}
      <div className="image-selection">
        <p className="report-creation__subtitle">
          <span className="name"> Satellite Image:</span>
        </p>
        <Dropdown
          name="select-satellite-dropdown"
          value={props.satelliteImage ? props.satelliteImage.imageID : ''}
          setValue={(imageID) => props.changeSatelliteImage(imageID)}
          valueList={props.satelliteImages}
          valueProperty="imageID"
          nameProperty="name"
        />
      </div>
      <ImageContainer
        imageSource={props.satelliteImage.png}
        className="report-creation__satellite-image"
        title={
          props.satelliteImage.name
            ? props.satelliteImage.name
            : 'Satellite Image:'
        }
      />
    </div>
  );
}
