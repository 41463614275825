import React, { useEffect, useState, useRef } from "react";
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import {
  getObservationsForProjects,
  getObservationsForLandowner,
  getProjects,
  getProjectDetails,
} from "../../apis/landsteward/accounts";
import {
  getObservationsForLandownerMock,
  getObservationsForProjectsMock,
  getObservationsForPropertyMock,
} from "../../apis/landsteward/mock_accounts";
import { MOCK_DATA } from "../../apis/landsteward/urlconfig";
import { 
  archiveProject, 
  archiveMowingReport, 
  archiveProperty, 
  getAllMDReport,
  renameProject, 
} from "../../apis/landsteward/accounts.js"

import Clip from "../../images/icons/clip-icon.png";

import "./selection-gallery.css";
import order from "../../images/icons/order-icon.png";
import ellipses from "../../images/icons/project-ellipses.png";
import useFetch from "../../apis/landsteward/useFetch";
import { terminateGeneratingReport } from "../../apis/landsteward/mowing.js"
import { RenamePopup } from "../modules/rename-popup"

/**
 *
 * @param {*} props
 *    <contentType>:
 *    <type>:
 *    <collectionList>:
 *    <createOption>:
 *    <createLink>:
 *    <IDAttribute>:
 *    <captionAttribute>:
 *    <imageAttribute>:
 *    <linkAttribute>:
 *    <defaultImage>:
 * @returns Selection Gallery which allows a user to select items which
 *  represent the objects in the provided collection list.
 */
export function SelectionGallery(props) {
  return (
    <div className="selection-gallery">
      <div className="selection-gallery-heading">
        <span> Gallery </span>
      </div>
      <SelectionGalleryCollection {...props} />
      {/** *}
      <SelectionGalleryCollection
        contentType={props.contentType}
        collectionList={props.collectionList}
        createOption={props.createOption}
        createLink={props.createLink}
      />
      {/** */}
    </div>
  );
}

export function SelectionGallery2(props) {
  return (
    <div className="selection-gallery">
      {/* <div className='selection-gallery-heading'>
      </div> */}
      <SelectionGalleryCollection2 {...props} />
      {/** *}
      <SelectionGalleryCollection
        contentType={props.contentType}
        collectionList={props.collectionList}
        createOption={props.createOption}
        createLink={props.createLink}
      />
      {/** */}
    </div>
  );
}

/**
 *
 * @param {*} props
 *    <contentType>: only 'image' and 'component' are supported
 *    <collectionList>: collection of objects which improve the code
 *
 *
 *    <createOption>: if true, a gallery item with a plus sign ('+') will be
 *      placed in the gallery. This will lead to another page to allows the
 *      user to create that item. In addition, the following must be defined:
 *    <createLink>: the link to which the user will go to create a gallery item
 */
export function SelectionGalleryCollection(props) {
  return (
    <div className="selection-gallery__collection">
      {
        /** Gallery Add New */
        props.createOption && (
          <SelectionGalleryItem
            key="SELECTION_GALLERY_ADD"
            // caption={'Add New ' + props.type}
            caption={"Add New"}
            link={props.createLink}
            add={true}
            showImage={props.showImage}
          >
            <button className="create-new-project">create project</button>

            {/* <p className="add-button">+</p> */}
          </SelectionGalleryItem>
        )
      }

      {
        /** Gallery Items */
        props.collectionList.map((item) => (
          <SelectionGalleryItem
            key={item[props.IDAttribute]}
            // caption={item[props.captionAttribute]}
            link={item[props.linkAttribute]}
            showImage={props.showImage}
          >
            {props.showImage && ( //item.contentType === 'image' ?
              // Nov 19 design removes the image -Leo
              <img
                src={
                  item[props.imageAttribute]
                    ? item[props.imageAttribute]
                    : props.defaultImage
                }
              />
            )}

            {item[props.captionAttribute]}
          </SelectionGalleryItem>
        ))
      }
    </div>
  );
}
export function SelectionGalleryCollection2(props) {
  return (
    <div className="selection-gallery__collection">
      {
        /** Gallery Items */
        
        props.collectionList.map((item) => (
          
          <SelectionGalleryItem2
            key={item[props.IDAttribute]}
            projectID={item["projectID"]}
            // caption={item[props.captionAttribute]}
            link={item[props.linkAttribute]}
            showImage={props.showImage}
            removeProject={props.removeProject}
            changeProject={props.changeProject}
            addProject={props.addProject}
          >
            {props.showImage && ( //item.contentType === 'image' ?
              // Nov 19 design removes the image -Leo
              <img
                src={
                  item[props.imageAttribute]
                    ? item[props.imageAttribute]
                    : props.defaultImage
                }
              />
            )}

            {item[props.captionAttribute]}
          </SelectionGalleryItem2>
        ))
      }
    </div>
  );
}

/**
 * Gallery Item component that can be hovered, selected and placed among
 * other items in a gallery. Contains a short title/caption describing what
 * the gallery item contains.
 * @param {*} props
 *    children: Components to display inside the gallery item to display
 *    caption: Necessary to describe each item separately
 *    add: Boolean to determin add button
 */
export function SelectionGalleryItem(props) {
  let style = {};
  if (props.add) {
    style["background"] = "#black";
  }
  if (props.showImage) {
    style["height"] = "16.5rem";
  }
  return (
    <figure className="selection-gallery__item" style={style}>
      <Link to={props.link} style={{ all: "unset" }}>
        <div className="selection-gallery__item__content">
          {props.children}

          {/* <div className='selection-gallery__item__caption'>
            {props.caption}
          </div> */}
        </div>
      </Link>
    </figure>
  );
}
export function SelectionGalleryItem2(props) {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isRenamingProject, setRenamingProject] = useState(false);
  const isMounted = useRef(true);

  let menuRef = useRef()
  let itemRef = useRef()

  useEffect(() => {
    document.addEventListener("mousedown", outsideClick);
    return () => {
      document.removeEventListener("mousedown", outsideClick);
    }
  })

  function outsideClick(event) {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      menuRef.current.style.display = "none";
    }
  }


  let style = {};
  if (props.add) {
    style["background"] = "#357960";
    // style['width'] = "240px";
    // style['height'] = "40px";
    // style['left'] = "143px";
    // style['top'] = "-2px";
    style["fontSize"] = "50";
  }
  if (props.showImage) {
    style["height"] = "16.5rem";
  }
  
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleChangeName = async (newName) => {
    setRenamingProject(true)
    const success = await renameProject(props.projectID, newName)

    setRenamingProject(false)

    if (success) {
      window.alert(`Successfully changed project name to: ${newName}`)
      props.removeProject(props.projectID)
        .then(props.addProject(await getProjectDetails(props.projectID)))
    } else {
      console.error("Failed to change project name")
    }
  }
  
  function showProjectDeletionConfirmation(project_id, project_name) {
    let result = confirm(`Are you sure you want to delete project: "${project_name}?"`);
    if (result == true) {
      archiveProject(project_id)
        .then(props.removeProject(project_id))
      getAllMDReport(project_id, 'mowing_detection')
        .then(mowingReports => mowingReports.filter(report => report.report_info === "generating"))
        .then(generatingReports => {
          generatingReports.forEach(report => terminateGeneratingReport(report.reportID))
        })
    }
  }

  return (
    <figure className="selection-gallery__item2" style={style} ref={itemRef}>
      <Link to={props.link} style={{ all: "unset" }}>
        <div className="selection-gallery__item__content">
          {props.children}

          <div className="selection-gallery__item__caption">
            {props.caption}

            {/* <div className='last-updated'>Last Updated: YYYY-MM-DD</div> */}
            {/* not currently implemented */}
          </div>
        </div>
      </Link>
      <div className="selection-gallery__item__context_menu" onClick={() => {
          let menu = menuRef.current;
          if (menu.style.display === "inline-block") {
            menu.style.display = "none";
          } else {
            menu.style.display = "inline-block";
          }
          // menu.addEventListener("mousedown", (event) => {menu.contains(event.target)}, () => {

          // })
      }}>
          <div className={`context-menu`} ref={menuRef}>
            <button onClick={() => setPopupVisible(true)}>Rename Project</button>
                {isPopupVisible && (
                  <RenamePopup
                    onRenameType={"Project"}
                    onClose={() => {
                      if (isMounted.current) {
                        setPopupVisible(false)
                      }
                    }}
                    onChangeName={handleChangeName}
                    onRenamingProgress={isRenamingProject}
                  />
                )}

            <button onClick={async () => {
              console.log(props.projectID)
              showProjectDeletionConfirmation(props.projectID, props.children[1])
              
              
            }}>Delete Project{props.caption}</button>
          </div>
          <img className="selection-gallery__item__context_menu-img" src={ellipses} />
          
      </div>
    </figure>
  );
}

function SelectionCardTitle(props) {
  return (
    <h4 className={`selection-card-title ${props.report_info === "failed" && "red-text"}`} >
      {props.report_info === "generating" && "Generating: "}
      {props.report_info === "haze detected" && "Haze detected: "}
      {props.report_info === "failed" && "Failed: "}
      {props.title}
    </h4>
  )

}

/**
 * Individual cards used for selecting property or observations
 * @param   {Array}   iconList    Array of icon that will be shown at the top of the card
 * @param   {String}   title
 * @param   {String}   content
 * @param   {String}   dateTime   optional String representation of time, will convert to date string if possible
 * @param   {String}   submittedBy   optional String that will be displayed as Submitted by:_______
 * @param   {String}   link   optional String url that this will will redirect to on click
 * @param   {Boolean}   mowingReport  true if it is a mowingReport, otherwise it is a property
 * @param   {Function}   onClick   function that will fire on click
 *
 *
 * @author  [Eugene Wong]
 */
export function SelectionCard(props) {

  useEffect(() => {
    document.addEventListener("mousedown", outsideClick);
    return () => {
      document.removeEventListener("mousedown", outsideClick);
    }
  })

  let menuRef = useRef();
  let itemRef = useRef();

  function outsideClick(event) {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      menuRef.current.style.display = "none";
    }
  }


  

  // to change
  function showDeletionConfirmation() {

    let result = confirm(`Are you sure you want to delete ${props.title}? This action cannot be undone.`);
    if (result == true) {
      if (props.report_info === "generating") {
        // send a request to valuation to stop the job with id equal to props.reportID
        terminateGeneratingReport(props.reportID);
      }
      if (props.mowingReport) {
        archiveMowingReport(props.reportID)
          .then((res) => {itemRef.current.style.display = "none"})
          // getMowingReportsBasic() is a function in project-details-page.js
          // gets updated mowing report data from Inventory and re-renders the page without the newly-archived report
          .then(props.getMowingReportsBasic())
      } else {
        archiveProperty(props.landownerID, props.propertyID)
          .then((res) => {itemRef.current.style.display = "none"})
          .then(props.getPropertiesBasic())
      } 
    }
    
  }

  // const d = new Date(props.dateTime);
  // let formatedD = props.dateTime
  //   ? d.toDateString() === "Invalid Date"
  //     ? props.dateTime
  //     : d.toDateString()
  //   : "";
  let formatedD = props.dateTime ? props.dateTime : "";
  if (props.mowingReport && formatedD) {
    formatedD =
      formatedD.substring(0, formatedD.length - 12) +
      " at " +
      formatedD.substring(11, formatedD.length);
  }
  let formattedStart = "";
  if (props.startDate) {
    formattedStart = props.startDate.slice(0,6) + props.startDate.slice(8,10)
  }
  let formattedEnd = "";
  if (props.endDate) {
    formattedEnd = props.endDate.slice(0,6) + props.endDate.slice(8,10)
  }
  // VVV add new icons that will show at the top here VVV
  // const icons = { clip: Clip };
  let index = props.currIndex != undefined ? props.currIndex : "";
  const body = (
    <div
      ref = {itemRef}
      className={"selection-card _" + index}
      
      style={
        props.add
          ? { background: "#357960" }
          : props.mowingReport && props.report_info !== "failed"
          ? { background: "#E5F4E4" }
          : props.report_info === "failed"
          ? { background: "#E7E7E7" }
          : null
      }
    >
      {/* {props.iconList && (
        <div className="selection-card-icons">
          {props.iconList.map(
            (item) =>
              item in icons && (
                <img
                  key={item}
                  src={icons[item]}
                  className="selection-card-icon"
                />
              )
          )}
        </div>
      )} */}

      <span className={`loader ${(props.report_info === "generating") ? "" : "hidden"}`}></span>

       
        <div className="selection-gallery__item__context_menu" onClick={() => {
          let menu = menuRef.current;
          if (menu.style.display === "inline-block") {
            menu.style.display = "none";
          } else {
            menu.style.display = "inline-block";
          }
          // menu.addEventListener("mousedown", (event) => {menu.contains(event.target)}, () => {

          // })
        }}>
            <div className={`context-menu`} ref={menuRef}>
              <button onClick={async () => {
                console.log(props)
                showDeletionConfirmation()
              }}>Delete</button>
            </div>
            <img className="selection-gallery__item__context_menu-img" src={ellipses} />
            
        </div>


      <div className="selection-card-content">
        {(props.report_info === "generating" || props.report_info === "failed" || !props.link) ? 
          <div style={{ all: "unset" }} onClick={(props.report_info === "generating" || props.report_info === "failed")? undefined : props.onClick}>
            <SelectionCardTitle 
              title = {props.title}
              report_info = {props.report_info} 
            />
          </div> :
          (props.report_info === "haze detected" ?
            <Link to={props.link} style={{ all: "unset" }} onClick={props.onClick}>
              <SelectionCardTitle 
                title = {props.title}
                report_info = {props.report_info} 
              />
            </Link> :
            <Link to={props.link} style={{ all: "unset" }} onClick={props.onClick}>
              <SelectionCardTitle 
                title = {props.title}
                report_info = {props.report_info} 
              />
            </Link>
          )
        }   
        {props.submittedBy && <p>Submitted by: {props.submittedBy}</p>}
        {props.content && <p>{props.content}</p>}
        {formattedStart && formattedEnd && (
          <p>
            Report range:{" "}
            <span>
              {formattedStart} to {formattedEnd}
            </span>
          </p>
        )}
        {props.children}
      </div>
      {props.mowingReport ? (
        <div className="selection-card-timestamp">
          <span>Date created:</span>
          {formatedD}
        </div>
      ) : null}
    </div>
  );
  // if (props.link && !props.unclickable) {
  //   return (
  //     <Link to={props.link} style={{ all: "unset" }}>
  //       {body}
  //     </Link>
  //   );
  // } else {
  //   return (
  //     <div style={{ all: "unset" }}>
  //       {body}
  //     </div>
  //   );
  // }
  return (
    <div style={{ all: "unset" }}>
      {body}
    </div>
  );
}


/**
 * Gallery of observation cards
 * @param   {String}  projectID gallery will only show observations in this project
 * @param   {String}  landownerID Optional, gallery will only show observations related to this land owner
 * @param IDAttribute
 * @param captionAttribute
 * @param submittedByAttribute
 * @param dateAttribute
 * @param createOption
 * @param createLink
 * @param setSelectedObservation
 *
 * @author  [Eugene Wong]
 */
export function ObservationsGalleryCollection(props) {
  let [getFail, setGetFail] = useState(false);
  let [observations, setObservation] = useState();

  useEffect(async () => {
    let result;
    if (props.propertyID) {
      result = MOCK_DATA
        ? getObservationsForPropertyMock(props.propertyID)
        : getObservationsForPropertyMock(props.propertyID);
    } else if (props.landownerID) {
      result = MOCK_DATA
        ? getObservationsForLandownerMock(props.projectID, props.landownerID)
        : await getObservationsForLandowner(props.projectID, props.landownerID);
    } else {
      result = MOCK_DATA
        ? getObservationsForProjectsMock(props.projectID)
        : await getObservationsForProjects(props.projectID);
    }

    if (props.data) {
      result = props.data;
    }
    if (!result) {
      setGetFail(true);
    } else {
      setObservation(result);
    }
  }, [props.projectID, props.landownerID, props.propertyID, props.data]);

  function formatObservationUrl(report) {
    // change to the correct url format after rerouting of report detail pages are done
    return (
      "/project/" +
      props.projectID +
      "/property/" +
      report.properties +
      "/" +
      report.reportType +
      "/" +
      report.reportID
    );
  }

  function formatMowingUrl(report) {
    const action = report.id_to_update ? "update" : "create";
    return (
      `/project/${props.projectID}/${report.reportID}/${report.report_info === "haze detected" ? `2/mowing-${action}` : "mowingdisplay"}`
    );
  }

  return (
    <div className="observations-gallery">
      {
        /** Gallery Add New */
        props.createOption && (
          <SelectionGalleryItem
            key="SELECTION_GALLERY_ADD"
            // caption={'Add New ' + props.type}
            caption={""}
            link={props.createLink}
            add={true}
            showImage={true}
          >
            {/* <button className='create-new-project'>create project</button> */}
            {/* <p className="add-button">+</p> */}
            <br />
          </SelectionGalleryItem>
        )
      }
      {
        /** Gallery Items */
        observations?.map((item, index) => {
          return (
            <SelectionCard
              key={item[props.IDAttribute]}
              title={item[props.captionAttribute]}
              submittedBy={item[props.submittedByAttribute]}
              dateTime={item[props.dateAttribute]}
              link={() =>
                createLink(item, props, formatMowingUrl, formatObservationUrl)
              }
              onClick={
                item[props.typeAttribute] === "field_observation" &&
                props.setSelectedObservation
                  ? () => props.setSelectedObservation(item[props.IDAttribute])
                  : null
              }
              currIndex={index}
              startDate={item.start_date}
              endDate={item.end_date}
              carbonReport={item.name}
              mowingReport={props.mowingReport}
              report_info={item.report_info}
              reportID = {item.reportID}
              getMowingReportsBasic = {props.getMowingReportsBasic}
            />
          );
        })
      }
    </div>
  );
}

function createLink(item, props, formatMowingUrl, formatObservationUrl) {
  if (props.mowingReport) {
    return formatMowingUrl(item);
  } else if (item.reportType == "carbon_calculation") {
    return "/project/" + props.projectID + "/view_carbon_report/" + item.name;
  } else if(
    !item[props.typeAttribute] ||
    item[props.typeAttribute] === "field_observation"
  ) {
    return null;
  } else {
    return formatObservationUrl(item);
  }
}

export function PropertyGalleryCollection(props) {
  return (
    <div className="observations-gallery">
      {
        /** Gallery Items */
        props.collectionList.map((item, index) => (
          <SelectionCard
            key={item[props.IDAttribute]}
            title={item[props.captionAttribute]}
            // iconList={["clip"]}
            onClick={props.onClickCallback(
              item[props.regionAttribute],
              item[props.nameAttribute]
            )}
            currIndex={index}
            propertyID={item[props.IDAttribute]}
            landownerID={props.landownerID}
            getPropertiesBasic={props.getPropertiesBasic}
          >
            {item.children}
          </SelectionCard>
        ))
      }

      {
        /** Gallery Add New */
        props.createOption && (
          <SelectionCard key="SELECTION_GALLERY_ADD" link={props.createLink}>
            {/* <button className='create-new-project'>create project</button> */}

            {/* <p className="add-button">+</p> */}
            <br />
          </SelectionCard>
        )
      }
    </div>
  );
}
