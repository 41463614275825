import React, { useState, useEffect } from "react";
import { Redirect, useParams, useRouteMatch } from "react-router-dom";

import { SelectionGallery } from "../components/modules/selection-gallery";

import MaryCreek from "../images/sample_region_image.png";
import "./ndvi-selection-page.css";

import { getAllMDReport } from "../apis/landsteward/accounts";

/**
 *
 * @param {*} props
 *    <project>: current project
 *    <region>: current region
 *    <reports>: all reports for the current project, including land covers
 *    <changeCurrentState>: sets the current project, region etc...
 */
export default function MowingBrowsePage(props) {
  let params = useParams();

  function galleryFormatMD(reports) {
    let outputArray = [];

    let objCopy;
    for (let i = 0; i < reports.length; i++) {
      objCopy = { ...reports[i] };

      if (!objCopy.png_image) {
        objCopy.png_image = MaryCreek;
      }
      if (!objCopy.path) {
        objCopy.path = `/project/${params.projectID}/${objCopy.reportID}/mowingdisplay`;
      }
      if (objCopy.reportType === "mowing_detection") {
        outputArray.push(objCopy);
      }
    }

    return outputArray;
  }

  /* ##### URL Parameters ##### */
  let [mdreports, setmdreports] = useState([]);
  let [projectFailed, setProjectFailed] = useState(false);
  let [regionFailed, setRegionFailed] = useState(false);

  const fetchMDreports = async () => {
    let allreports = await getAllMDReport(params.projectID, 'mowing_detection');
    allreports = allreports.filter(report => !report.is_archived)
    return allreports;
  };

  useEffect(async () => {
    let isCorrectState = await props.changeCurrentState({
      projectID: params.projectID,
    });
    if (!isCorrectState.project) {
      console.error("Failing Projects", isCorrectState);
      setProjectFailed(true);
    }
  }, []);

  useEffect(() => {
    fetchMDreports().then((data) => {
      setmdreports(data);
    });

  return () => {
    setmdreports([]);
  }
  }, []);

  if (projectFailed) {
    return <Redirect to="/project" />;
  } else if (regionFailed) {
    return <Redirect to={`/project/${params.projectID}`} />;
  } else {
    /* ##### Render ##### */
    return (
      <form>
        <div className="ndvi-selection-page">
          <div className="satellite-image-create-page-title">
            <h2>Browse or Add Mowing Detection Reports</h2>
          </div>

          <SelectionGallery
            title={
              props.project && props.region
                ? `${props.project.name} NDVIs for ${props.region.name}`
                : "NDVIs"
            }
            titleClassName="ndvi-selection__title"
            contentType="image"
            collectionList={galleryFormatMD(mdreports ? mdreports : [])}
            type="md Report"
            IDAttribute="reportID"
            captionAttribute="name"
            imageAttribute="png_image"
            linkAttribute="path"
            createOption={true}
            createLink={`/project/${params.projectID}/mowing-create`}
          />
        </div>
      </form>
    );
  }
}
