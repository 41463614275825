import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  addLandownerToProject,
  createLandownerProfile,
  getAllUserEmails,
  getAllLandowners
} from "../apis/landsteward/accounts";
import { createLandownerProfileMock } from "../apis/landsteward/mock_accounts";
import { MOCK_DATA } from "../apis/landsteward/urlconfig";
import { PageHeader } from "../components/modules/page-header";
import "./landowner-new-page.css";

/**
 * Page for creating new landowner profile (doesn't support organizations and inviting landowners to create accounts yet)
 *
 * @param   {Object}    project       current project object
 * @param   {function}  changeProject function to change top level project
 *
 * @author  [Leo Wang](https://github.com/Korotu-Leo-Wang)
 */
export default function LandownerNewPage(props) {
  const params = useParams();

  const [page, setPage] = useState(0);

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [streetName, setStreetName] = useState("");
  const [buildingNum, setBuildingNum] = useState("");
  const [postCode, setPostCode] = useState("");
  const [townName, setTownName] = useState("");
  const [countrySub, setCountrySub] = useState("");
  const [country, setCountry] = useState("");

  const [warning, setWarning] = useState("");

  const [redirectToProject, setRedirectBack] = useState(false);
  const [projectDoesNotExist, setProjectFail] = useState(false);

  const fullNameMaxLength = 70;
  const emailMaxLength = 2048;
  const buildingNumMaxLength = 16;
  const streetMaxLength = 70;
  const cityMaxLength = 25;
  const regionMaxLength = 35;
  const countryMaxLength = 2;
  const postCodeMaxLength = 16;

  useEffect(async () => {
    let success = await props.changeProject(params.projectID);
    if (!success) {
      setProjectFail(true);
    }
  }, []);

  const newLandownerProfileNext = async (e) => {
    e.preventDefault();
    if (page === 0) {
      if (!fullName || !email) {
        let missing = [];
        if (!fullName) {
          missing.push("Full Name");
        }
        if (!email) {
          missing.push("E-mail Address");
        }

        setWarning(`Missing required fields: ${missing.join(", ")}`);
      } else {
        if (!ValidateEmail(email)) {
          setWarning("invalid email address");
          return;
        } else {
          setPage(page + 1);
          setWarning("");
        }
      }
    } else if (page === 1) {
      if (!buildingNum || !streetName || !townName || !countrySub || !country || !postCode) {
        let missing = [];
        if (!buildingNum) {
          missing.push("Building Number");
        }
        if (!streetName) {
          missing.push("Street");
        }
        if (!townName) {
          missing.push("City");
        }
        if (!countrySub) {
          missing.push("Region");
        }
        if (!country) {
          missing.push("Country");
        }
        if (!postCode) {
          missing.push("Postal Code");
        }

        setWarning(`Missing required fields: ${missing.join(", ")}`);
      } else {
        // tests if the string has only letters
        // https://bobbyhadz.com/blog/javascript-check-if-string-contains-only-letters#:~:text=Use%20the%20test()%20method,only%20letters%20and%20false%20otherwise.
        if (!/^[a-zA-Z]+$/.test(country)) {
          setWarning("Country field must only be letters");
          return;
        } else if (country.length != 2) {
          setWarning("Country abbreviation must have only 2 letters");
          return;
        }
        const body = {
          email: email,
          name: fullName,
          street_name: streetName,
          building_num: buildingNum,
          post_code: postCode,
          town_name: townName,
          country_sub: countrySub,
          country: country,
          address: `${buildingNum} ${streetName}`,
        };
        let newLandowner; 
        if (MOCK_DATA) {
          newLandowner = createLandownerProfileMock()
        } else {
          const profiles = await getAllUserEmails();
          //console.log("this is profiles: ", profiles)
          if (!(profiles.includes(email))) {
            newLandowner = await createLandownerProfile(body);
          }
        }
        // const newLandowner = MOCK_DATA
        //   ? createLandownerProfileMock()
        //   : await createLandownerProfile(body);
        if (newLandowner) {
          const addLandowner = MOCK_DATA
            ? addLandownerToProjectMock()
            : await addLandownerToProject(params.projectID, email);
          if (addLandowner) {
            alert("Successfully created and added new Landowner to project!");
            setRedirectBack(true);
          } else {
            alert("Failed to add Landowner to project");
          }
        } else {
          alert("Landowner creation failed.");
        }
      }
    }
  };

  const handleBackButton = () => {
    setWarning("");
    setPage(page - 1);
  };

  if (projectDoesNotExist) {
    return <Redirect to={"/project"} />;
  } else if (redirectToProject) {
    return <Redirect to={`/project/${params.projectID}`} />;
  } else {
    return (
      <div>
        <form
          className="landowner-new__form page-margins"
          onSubmit={newLandownerProfileNext}
        >
          {page === 0 ? (
            <>
              <PageHeader
                headerText="Create a New Landowner Profile"
                // buttonsNeeded={true}
                // headerIconNeeded={true}
              />
              <div
                className="landowner-new__form__body"
                style={{
                  gridTemplateColumns: "1fr 1fr 1fr auto",
                  gridTemplateRows: "1fr 1fr 1fr",
                }}
              >
                <div>
                  <LandownerGridFormTextEntry
                    value={fullName}
                    changeValue={setFullName}
                    style={{ gridColumnStart: 1, gridColumnEnd: 2 }}
                    label="Landowner's Full Name"
                    inputName="landowner-new-fullname"
                    maxLength={fullNameMaxLength}
                  />
                  <p 
                    className={fullName.length===fullNameMaxLength ? 'landowner-new_text-limit-colour' : undefined}
                    style={{ textAlign: 'right', marginTop: '10px' }}
                  >
                    {fullName.length}/{fullNameMaxLength}
                  </p>
                </div>
                <LandownerGridFormTextEntry
                  value={email}
                  changeValue={setEmail}
                  style={{ gridColumnStart: 1, gridColumnEnd: 2 }}
                  label="Landowner's Primary Email Address"
                  inputName="landowner-new-email"
                  maxLength={emailMaxLength}
                />
              </div>
            </>
          ) : null}

          {page === 1 ? (
            <>
              <PageHeader
                headerText="Add Landowner Details"
                // buttonsNeeded={true}
                // headerIconNeeded={true}
              />
              <div
                className="landowner-new__form__body"
                style={{
                  gridTemplateColumns: "1fr 1fr",
                  gridTemplateRows: "1fr 1fr 1fr",
                  width: "30%",
                }}
              >
                <LandownerGridFormTextEntry
                  value={buildingNum}
                  changeValue={setBuildingNum}
                  label="Building Number"
                  inputName="landowner-new-building"
                  page={1}
                  maxLength={buildingNumMaxLength}
                />

                <LandownerGridFormTextEntry
                  value={streetName}
                  changeValue={setStreetName}
                  label="Street"
                  inputName="landowner-new-street"
                  page={1}
                  maxLength={streetMaxLength}
                />

                <LandownerGridFormTextEntry
                  value={townName}
                  changeValue={setTownName}
                  style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
                  label="City"
                  inputName="landowner-new-town"
                  page={1}
                  maxLength={cityMaxLength}
                />
                <div className="landowner-new_form_last_row">
                  <LandownerGridFormTextEntry
                    value={countrySub}
                    changeValue={setCountrySub}
                    style={{ marginRight: "2em" }}
                    label="Region"
                    inputName="landowner-new-country__sub"
                    page={1}
                    maxLength={regionMaxLength}
                  />

                  <LandownerGridFormTextEntry
                    value={country}
                    changeValue={setCountry}
                    style={{ marginRight: "2em" }}
                    label="Country"
                    inputName="landowner-new-country"
                    page={1}
                    placeholder="CA"
                    maxLength={countryMaxLength}
                  />

                  <LandownerGridFormTextEntry
                    value={postCode}
                    changeValue={setPostCode}
                    label="Postal Code"
                    inputName="landowner-new-post"
                    page={1}
                    maxLength={postCodeMaxLength}
                  />
                </div>
              </div>
            </>
          ) : null}
          <div className="landowner-new__button">
            <p className="landowner-new__warning-text">{warning}</p>

            <input
              className="landowner-new__create-btn"
              name="submit-btn"
              type="submit"
              value={page == 0 ? "Confirm and Continue" : "Confirm and Finish"}
            />

            <button
              className="landowner-new__cancel-btn"
              onClick={() => setRedirectBack(true)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  }
}

/**
 * TODO: Generalize this component for all forms on LS
 *
 * @param {function}  changeValue
 * @param {string}    value
 * @param {object}    style
 * @param {string}    label
 * @param {string}    inputName
 */
function LandownerGridFormTextEntry(props) {
  //const {placeholder} = props
  return (
    <div className="landowner-new__input-block" style={props.style}>
      <label htmlFor={props.inputName}>{props.label}</label>
      <input
        name={props.inputName}
        value={props.value}
        onChange={(event) => props.changeValue(event.target.value)}
        type="text"
        placeholder={props.placeholder ? props.placeholder : ""}
        maxLength={props.maxLength}
      />
    </div>
  );
}

// from https://www.w3resource.com/javascript/form/email-validation.php
function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}
