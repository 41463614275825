import React, { useCallback, useState, useEffect, useRef, Component } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { Line } from "react-chartjs-2";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineController, LineElement } from "chart.js";

import "./mowing-display-page.css";
import "./mowing-create-page.css";

import { stringifyNumber } from "../utils/helpers";
import { MapboxRegionSelection } from '../components/mapbox/mapbox-region-selection';
import { MOCK_DATA } from "../apis/landsteward/urlconfig";
import { download, getMDReport, getMDReportCSV, getNDVIReport } from "../apis/landsteward/accounts";
import { getLandownerDetails, getLandowners, getPropertiesForLandowner, getDataForRegion } from "../apis/landsteward/accounts";
import { getDataForRegionMock, getPropertiesForLandownerMock } from "../apis/landsteward/mock_accounts";

import info from '../images/icons/info-icon.png';

export function MowingDisplayPage(props) {
  const params = useParams();
  const history = useHistory();

  /** CONSTANTS */
  const project = props.searchState("projects", "projectID", params.projectID);
  const [reportInfo, setReportInfo] = useState({});
  const [reportData, setReportData] = useState([]);
  const [reportDataKeys, setReportDataKeys] = useState([]);
  const [mowingData, setMowingData] = useState({});

  /** FLAGS */
  const [projectFailed, setProjectFailed] = useState(false);
  const [displayUpdatePopup, setDisplayUpdatePopup] = useState(false);

  const [imageTime, setImageTime] = useState([]);
  const [trimmedMowingData, setTrimmedMowingData] = useState({});
  const [selectedField, setSelectedField] = useState("");
  const [centroidIndex, setCentroidIndex] = useState(0);
  const [centroids, setCentroids] = useState([]);
  const chartRef = useRef(null)

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineController, LineElement);

  async function getAllProperties(landowners) {
    let allProperties = [];

    for (const landowner of landowners) {
      // right now just worry about getting all of the properties on there, deal with landowner-specific info after
      let id = landowner.profileID;
      let propertiesForLandowner = MOCK_DATA ? getPropertiesForLandownerMock(id) : await getPropertiesForLandowner(id);
      allProperties = allProperties.concat(propertiesForLandowner);
    }

    return allProperties;
  }

  async function formatPropertiesForMap(propertiesToFormat) {
    let formattedPropertyArray = [];
    // it's indexing it by landowner, need to extract them and put them into an array that doesn't differentiate it
    let propertiesArr = propertiesToFormat;
    let regionInfo;

    for (let index = 0; index < propertiesArr.length; index++) {
      regionInfo = MOCK_DATA
        ? getDataForRegionMock(propertiesArr[index].region)
        : await getDataForRegion(propertiesArr[index].region);
      if (regionInfo) {
        formattedPropertyArray.push(regionInfo);
      } else {
        console.error(
          "Error getting region info for property",
          propertiesArr[index].id
        );
      }
    }

    return formattedPropertyArray;
  }

  function readCSVFile(response) {
    console.log("this is response: ", response)
    let lines = response.split('\n');
    let modifiedResponse = "";
    let timeArray = [];
    for (var i = 0; i < lines.length; i++) {
      var row = lines[i].split(","); //First column (Split on the separator!)
      if (row[0] == "Date") {
        modifiedResponse = modifiedResponse.concat(row + "\n");
        continue;
      } else if (row[0] != "Date" && row[0] != "") {
        let timeRemoved = row[0].slice(0, 10);
        let time = row[0].slice(11, 19);
        timeArray.push(time);
        row[0] = timeRemoved;
        let modifiedRow = row.join(",");
        modifiedResponse = modifiedResponse.concat(modifiedRow + "\n");
      }
      //Do your stuff
    }
    console.log("this is modifiedResponse:", modifiedResponse)
    return { modifiedResponse: modifiedResponse, timeArray: timeArray };
  }

  function removeIDs(response) {
    var lines = response.split("\n");
    var csvLength = lines.length
    let modifiedResponse = "";

    for (var i = 0; i < csvLength-1; i++) {
      var row = lines[i].split(","); //First column (Split on the separator!)

      row.splice(9, 2)
      modifiedResponse = modifiedResponse.concat(row.join(",") + "\n")

      //Do your stuff 
    }
    console.log(modifiedResponse)
    return modifiedResponse;
  }

  const [, setRegionName] = useState("");
  let [landowners, setLandowners] = useState([]);
  const [regionID, setRegionID] = useState("");

  // for showing all of the properties on the map
  let [properties, setProperties] = useState([]);

  const [formattedProperties, setFormattedProperties] = useState([]);
  let [slideImages, setSlideImages] = useState([]);
  const [loadingFeatures, setLoadingFeatures] = useState(true);
  let [projectDoesNotExist, setProjectFail] = useState(false);

  useEffect(() => {
    async function proc2() {
      console.log("proc2 done");

      let slideImages5 = await getSlideImages();
      setSlideImages(slideImages5);

      return;
    }
    proc2();
  }, [mowingData]);

  useEffect(() => {
    getMDReportCSV(params.mowingID, 'mowing_detection').then((response) => {      
      let lines = response.split('\r\n');
      let keys = lines[0].split(',');
      let arr = [];

      for (var i = 1; i < lines.length - 1; i++) {
        let row = lines[i].split(',');
        let obj = {}
        for (j = 0; j < keys.length; j++) obj[keys[j]] = row[j];
        arr.push(obj)
      }
      setReportData(arr);
      setReportDataKeys(keys);

      // updated to include regular 'response' variable to obtain image capture time
      let result = readCSVFile(response)
      let timeRemovedResponse = result.modifiedResponse
      let timeArray = result.timeArray;
      let prunedResponse = removeIDs(timeRemovedResponse)
      setMowingData(timeRemovedResponse);
      setTrimmedMowingData(prunedResponse);
      setImageTime(timeArray);
    }),
      console.log("this is params.mowingID: ", params.mowingID)
      console.log("this is params.projectID: ", params.projectID)
      getMDReport(params.mowingID, 'mowing_detection').then((result) => {
        setReportInfo(result);
        //console.log("this is result (for MowingInfo): ", result)
      });

    async function proc() {
      const projectID = params.projectID;
      let success = await props.changeProject(projectID);

      if (!success) {
        setProjectFail(true);
        return;
      }

      setLoadingFeatures(true);
      let landowners = MOCK_DATA
        ? getLandownersMock(projectID)
        : await getLandowners(projectID);
      let allProperties = await getAllProperties(landowners);
      let landowners_details = [];

      if (MOCK_DATA) {
        landowners_details = landowners;
      } else {
        for (let index = 0; index < landowners.length; index++) {
          let landowner_detail = await getLandownerDetails(
            projectID,
            landowners[index].profileID
          );
          if (landowner_detail) {
            landowners_details.push(landowner_detail);
          } else {
            console.error(
              "Failed to get landowner details for",
              landowners[index].profileID
            );
          }
        }
      }

      setProperties(allProperties);
      let allFormattedProperties = await formatPropertiesForMap(allProperties);
      setLandowners(landowners_details);
      setFormattedProperties(allFormattedProperties);

      setLoadingFeatures(false);
    }
    props.changeCurrentState(params.projectID).then((isCorrectState) => {
      if (!isCorrectState.project) {
        console.error("Failing Projects", isCorrectState);
        setProjectFailed(true);
      }
    });

    proc().catch(console.error);
    //clean state on unmount, use to avoid warning
    return () => setMowingData({});
  }, []);

  //once formatted properties have been computed, determine the centroids of all properties
  useEffect(() => {
    if (formattedProperties) {
      let centroidArr = [];
      for (let index = 0; index < formattedProperties.length; index++) {
        let coordinateArr =
          formattedProperties[index].data.geometry.coordinates[0];
        let centroid = props.computeCentroid(coordinateArr);
        centroidArr.push(centroid);
      }
      setCentroids(centroidArr)
      console.log('centroids computed', centroidArr)
    }
  }, [formattedProperties])

  //once a field has been selected, determine the index corresponding to that field 
  useEffect(() => {
    if (selectedField) {
      console.log('singlefield changed', selectedField)
      console.log('formattedProperties', formattedProperties);
      for (let i = 0; i < formattedProperties.length; i++) {
        let property = formattedProperties[i];
        console.log('property name', property.name)
        if (property.name == selectedField) {
          setCentroidIndex(i);
          console.log('centroid of current field: ', centroids[i])
          break;
        }
      }
    }
  }, [selectedField])


  async function getSlideImages() {
    let dataArray = getIDArray();
    const j = dataArray.length;
    let slide = [];
    if (reportInfo.properties != null) {
      for (let i = 0; i < j; i += reportInfo.properties.length) {
        // wait for the promise to resolve before advancing the for loop
        let response = await getNDVIReport(dataArray[i]);
        if (response) {
          slide.push({ url: response.image });
        }
      }
    }
    return slide;
  }

  function getIDArray() {
    let i = String(mowingData).split("\n").join(",").split(",").length;
    let str = String(mowingData).split("\n").join(",").split(",");

    let IDArray = [];

    const j = i;

    for (var n = 12; n < j - 1; n += 12) {
      IDArray.push(str[n + 10]);
    }
    return IDArray;
  }

  var singlefieldNDVI = [];
  var singlefielddate = [];
  var dateArray = [];
  var singlefieldsatID = [];
  var mowedFields = [];
  var mowedDate = [];
  var mowedTable = [];
  var singleSatImageCreationTime = [];
  var singlefieldSat = [];

  var strr = String(mowingData).split("\n").join(",").split(",");
  var b = String(mowingData).split("\n").join(",").split(",").length;
  var row = 0
  for (var p = 0; p <= b; p++) {
    if (p % 12 === 0 && p !== 0 && p !== 12) {
      row++;
    }
    if (strr[p] == selectedField) {
      //console.log("this is singlefield: ", singlefield); // e.g., f95
      singlefieldNDVI.push(strr[p + 3]);
      if (strr[p + 3] === 'NaN') {
        singleSatImageCreationTime.push(NaN)
      } else {
        singleSatImageCreationTime.push(imageTime[row])
      }

      singlefielddate.push(strr[p - 2]);
      singlefieldsatID.push(strr[p + 7]);
      //console.log("this is singlefieldsatID: ", singlefieldsatID);
      
      // get the satellite (Landsat or Sentinel) for each image
      //console.log("this is strr[p+9]: ", strr[p+9]);
      if (strr[p + 9] === 'HLSS30.020\r') {
        //console.log("it worked: HLSS30");
        singlefieldSat.push('Sentinel');
      } else if (strr[p + 9] ==='HLSL30.020\r') {
        //console.log("it worked: HLSL30");
        singlefieldSat.push('Landsat');
      } else {
        console.log("it did not work");
      }
      //console.log("after an iteration of pushing to satellite name: ", singlefieldSat);
    } else if (strr[p] == "True") {
      mowedFields.push(strr[p - 6]);
      mowedDate.push(strr[p - 8]);
    } else {
      continue;
    }
  }
  for (var i = 0; i < mowedFields.length; i++) {
    mowedTable.push([mowedFields[i], mowedDate[i]]);
  }

  var heading = ["Field Name", "Date Mowed"];
  var bodyDict = {};
  for (let i = 0; i < mowedTable.length; i++) {
    if (mowedTable[i][0] in bodyDict) {
      // if the date is already in the list, skip it
      if (bodyDict[mowedTable[i][0]].includes(mowedTable[i][1])) {
        continue;
      }
      bodyDict[mowedTable[i][0]].push(mowedTable[i][1]);
    } else {
      bodyDict[mowedTable[i][0]] = [mowedTable[i][1]];
    }
  }
  var heading = ["Field Name", ...Object.keys(bodyDict)];

  var compiledBody = [];
  let longestValue = 0;

  for (let key in bodyDict) {
    let value = bodyDict[key];
    if (value.length > longestValue) {
      longestValue = value.length;
    }
  }

  let j = 0;
  while (j < longestValue) {
    let ordinal =
      stringifyNumber(j + 1)
        .charAt(0)
        .toUpperCase() + stringifyNumber(j + 1).slice(1);
    let currDates = [ordinal + " Mowed"];

    for (let i = 1; i < heading.length; i++) {
      let currValue = bodyDict[heading[i]];
      if (currValue.length < j + 1) {
        currDates.push("N/A");
      } else {
        currDates.push(currValue[j]);
      }
    }
    compiledBody.push(currDates);
    j++;
  }

  const csv_array = []
  csv_array.push(heading)
  var body = compiledBody;
  for (let i=0; i<body.length; i++) {
    csv_array.push(body[i])
  }
  var CSVData = csv_array

  var CSVFile = CSVData.map(e=>e.join(",")).join("\n"); 
  console.log(trimmedMowingData)

  for (var p = 12; p <= b; p += 12) {
    dateArray.push(strr[p]);
  }

  function singleFieldData(trimmedData){
    let allFieldData = ""
    allFieldData = allFieldData.concat(trimmedData)
    var lines = allFieldData.split("\n");
    var csvLength = lines.length
    let singleFieldData = "";

    var attributes = lines[0].split(",");
    singleFieldData = singleFieldData.concat(attributes.join(",") + "\n");

    for (var i = 1; i < csvLength-1; i++) {
      var row = lines[i].split(","); 
      if (row[2] == selectedField) {
        singleFieldData = singleFieldData.concat(row.join(",") + "\n");
      } 
    }
    
    return singleFieldData;
  }

  var SatelliteObservationsData = trimmedMowingData
  var SatelliteObservTableName = 'all fields'
  if (singlefieldNDVI[0] != null){
    SatelliteObservationsData = singleFieldData(trimmedMowingData);
    SatelliteObservTableName = selectedField
  }

  function downloadChart(){
    if (singlefieldNDVI[0] != null){
      const link = document.createElement("a");
      link.download = `${reportInfo.name}_${selectedField}_chart.png`
      link.href=chartRef.current.toBase64Image();
      link.click();
    }
    else if (selectedField.length == 0){
      window.alert("Please enter a field name");
    }
    else{
      window.alert("No average NDVI data for this field, please enter another field name");
    }
  }

  function downloadDataTable() {
    let dataString = "";

    for (let i = 0; i < reportDataKeys.length; i++) {
      let key = reportDataKeys[i];

      if (!(key == "satID" || key == "ndviID")) {
        dataString += key;
        dataString += (key == "Data Source") ? "\r\n" : ","
      }
    }

    for (let i = 0; i < reportData.length; i++) {
      let image = reportData[i];
      for (let j = 0; j < reportDataKeys.length; j++) {
        let key = reportDataKeys[j];

        if (!(key == "satID" || key == "ndviID")) {
          dataString += image[key];
          dataString += (key == "Data Source") ? "\r\n" : ","
        }
      }
    }

    download(dataString, reportInfo.name, "SatelliteObservations");
  }

  /** COMPONENTS */

  function ReportOverview() {
    return (
      <div className="mowing-report-overview">
        <div className="mowing-report-project-name">
          <p className="mowing-report-text-bolded">
            Project: {project ? project.name : ""}
          </p>
        </div>
        <div className="mowing-report-description">
          <p className="mowing-report-text-unbolded">
            This document reports on the level of hay cover on every field within the selected region on the map below that belongs to the project {project ? project.name : ""}.
          </p>
        </div>
        <div className="mowing-report-details">
          <div className="mowing-report-details-title">
            <p className="mowing-report-text-bolded">
              Report Details
            </p>
          </div>
          <div className="mowing-report-details-body">
            <div className="mowing-report-details-row">
              <p className="mowing-report-text-unbolded">
                Report Created:
              </p>
              <p className="mowing-report-text-bolded">
                {reportInfo.creation_date && reportInfo.creation_date.substring(0, 10).replaceAll("/", "-")}
              </p>
            </div>
            <div className="mowing-report-details-row">
              <p className="mowing-report-text-unbolded">
                Start Date:
              </p>
              <p className="mowing-report-text-bolded">
                {reportInfo.start_date && reportInfo.start_date.substring(0, 10).replaceAll("/", "-")}
              </p>
            </div>
            <div className="mowing-report-details-row">
              <p className="mowing-report-text-unbolded">
                End Date:
              </p>
              <p className="mowing-report-text-bolded">
                {reportInfo.end_date && reportInfo.end_date.substring(0, 10).replaceAll("/", "-")}
              </p>
            </div>
            <div className="mowing-report-details-row">
              <p className="mowing-report-text-unbolded">
                Image Size:
              </p>
              <p className="mowing-report-text-bolded">
                {reportInfo.spatial_res && `${reportInfo.spatial_res}M`}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function ReportMap() {
    return (
      <div className="mowing-map">
        {loadingFeatures ? null : (
          <MapboxRegionSelection
            longitude={-63.1311}
            latitude={46.2382}
            zoom={9}
            style="mapbox://styles/korotu-conroy-trinh/ckwqnggaf0esg14odvehgi6hd"
            setRegionID={setRegionID}
            geoJsonID={regionID ? getGeojsonFromRegionID(regionID).id : ""}
            setRegionName={setRegionName}
            customPreloadedFeatures={formattedProperties}
            selectedPreloadedFeatureRegionID={regionID}
            mapWidth={"364px"}
            mapHeight={"168px"}
            drawEnabled={false}
          />
        )}
      </div>
    )
  }

  function ReportButtons() {
    return (
      <div className="mowing-form-buttons">
        <button
          className="mowing-form-dark-button"
          onClick={() => history.push(`/project/${params.projectID}/mowing-update`, {
            projectID: params.projectID,
            reportID: params.mowingID,
            reportName: reportInfo.name,
            startDate: reportInfo.start_date,
            version: reportInfo.version
        })}
        >
          Update Report
          <img 
            src={info}
            onMouseEnter={() => {setDisplayUpdatePopup(true)}}
            onMouseOut={() => {setDisplayUpdatePopup(false)}}
          >
          </img>
        </button>
        {
          displayUpdatePopup &&
          <div className="mowing-form-popup-notice">
            <div className="mowing-form-popup-notice-text">
              Updating your report allows you to select a new end date<br></br>so you may add new data to your existing report
            </div>
          </div>
        }
        <button
          className="mowing-form-light-button"
          onClick={() => download(CSVFile, reportInfo.name, 'Summary')}
        >
          Download Report (.csv)
        </button>
      </div>
    )
  }

  function MowingEventSummary() {
    return (  
      <table className="mowing-event-summary">
        <thead>
          <tr>
            <th className="mowing-event-summary-column-header">
              Field Name
            </th>
            {Object.keys(bodyDict).map((fieldName) => {
              return (
                <th>{fieldName}</th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {body.map((row) => {
            return (
              <tr>
                {row.map((val, index) => {
                  if (index === 0) return <th className="mowing-event-summary-column-header">{val}</th>;
                  else return <td>{val}</td>;
                })}
              </tr>
            )
          })}
        </tbody>
      </table> 
    )
  }

  function SatelliteObservations() {
    function getSatellite(source) {
      return source[3] == 'S' ? 'Sentinel' : 'Landsat';
    }

    return (
      <table className="mowing-event-summary">
        <thead>
          <tr>
            <th className="mowing-event-summary-column-header"></th>
            <th className="mowing-event-summary-column-header">Landowner</th>
            <th className="mowing-event-summary-column-header">Field ID</th>
            <th className="mowing-event-summary-column-header">Average NDVI</th>
            <th className="mowing-event-summary-column-header">Max NDVI</th>
            <th className="mowing-event-summary-column-header">Min NDVI</th>
            <th className="mowing-event-summary-column-header">Field Mowed</th>
            <th className="mowing-event-summary-column-header">Image Source</th>
          </tr>
        </thead>
        <tbody>
          {reportData.map((imageData) => {
            return(
              <tr>
                <td>{imageData['Date']}</td>
                <td>{imageData['Landowner']}</td>
                <td>{imageData['Field Name']}</td>
                <td>{Number(imageData['Average NDVI']).toFixed(2)}</td>
                <td>{Number(imageData['Max NDVI']).toFixed(2)}</td>
                <td>{Number(imageData['Min NDVI']).toFixed(2)}</td>
                <td>{imageData['Mowed']}</td>
                <td>{getSatellite(imageData['Data Source'])}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  /** ##### RENDER #####  */
  return (
    <div className="page-margins">
      <div className="mowing-report">
        <h3 className="mowing-section-header">
          {reportInfo.name}
        </h3>
        <div className="mowing-columns">
          <ReportOverview></ReportOverview>
          <ReportMap></ReportMap>
        </div>
        <ReportButtons></ReportButtons>
        <h3 className="mowing-section-header">
          Summary of Mowing Events
        </h3>
        {
          body.length == 0 ?
            <p className="mowing-report-text-unbolded">No mowing events were detected.</p> : 
            <MowingEventSummary></MowingEventSummary>
        }
        <h3 className="mowing-section-header">
          Graph of Average NDVI Values
          {
            reportInfo.start_date &&
            ` - ${reportInfo.start_date.substring(0, 10).replaceAll("/", "-")}`
          }
          {
            reportInfo.end_date &&
            ` to ${reportInfo.end_date.substring(0, 10).replaceAll("/", "-")}`
          }
          <select required
            className="mowing-field-selector"
            onChange={(event) => setSelectedField(event.target.value)}
          >
            <option value="" disabled selected hidden>Select a field...</option>
            <option value="">None</option>
            {formattedProperties.map((property) => (<option value={property.name}>{property.name}</option>))}
          </select>
          <button className="text-button" onClick={downloadChart}>
              <h5>Download</h5>
          </button>
        </h3>
        <p>Click on points to view images!</p>
        <div>
          <Line
            ref={chartRef}
            data={{
              labels: singlefielddate,
              datasets: [
                {
                  label: "Average NDVI",
                  data: singlefieldNDVI,

                  borderColor: ["rgba(0, 0, 0,)"],
                  pointBorderColor: ["rgba(0, 0, 0, 5)"],
                  pointStyle: "circle",
                  pointRadius: 6,
                  borderWidth: 3,
                  borderDash: [10, 5],
                  spanGaps: true,
                },
                {
                  label: "Date",
                  data: singlefielddate,
                  hidden: true,
                },
                {
                  label: "Time",
                  data: singleSatImageCreationTime,
                  hidden: true,
                },
                {
                  label: "Satellite",
                  data: singlefieldSat,
                  hidden: true,
                },
              ],
            }}
            height={400}
            width={600}
            options={{
              plugins:{
                legend: {
                  labels: {
                    filter: function(legendItem, chartData) {
                      return legendItem.datasetIndex === 0;
                    }
                  }
                },
                tooltip: {
                  callbacks: {
                    title: function() {return null},
                    label: function(tooltipItem) {
                      const ndviLabel = tooltipItem.chart.data.datasets[0].label;
                      const ndviValue = tooltipItem.chart.data.datasets[0].data[tooltipItem.dataIndex];
                      const dateLabel = tooltipItem.chart.data.datasets[1].label;
                      const dateValue = tooltipItem.chart.data.datasets[1].data[tooltipItem.dataIndex];
                      const timeLabel = tooltipItem.chart.data.datasets[2].label;
                      const timeValue = tooltipItem.chart.data.datasets[2].data[tooltipItem.dataIndex];
                      const satLabel = tooltipItem.chart.data.datasets[3].label;
                      const satValue = tooltipItem.chart.data.datasets[3].data[tooltipItem.dataIndex];

                      return [
                        `${ndviLabel}: ${ndviValue}`,
                        `${dateLabel}: ${dateValue}`,
                        `${timeLabel}: ${timeValue} UTC`,
                        `${satLabel}: ${satValue}`
                      ];
                    }
                  }
                }
              },
              onClick: (e, element) => {
                if (element.length > 0) {
                  console.log("index:", centroidIndex);
                  //console.log("here is singlefieldsatID and the [centroidIndex]:", singlefieldsatID, singlefieldsatID[centroidIndex])
                  console.log("Property Centroid: " + centroids[centroidIndex]);
                  //console.log("this is params:", params)

                  history.push(
                    `/project/${params.projectID}/property/${params.mowingID}/manual-detection`,
                    {
                      satIDIndex: element[0].index,
                      centroid: centroids[centroidIndex],
                      field: selectedField,
                      reportDates: singlefielddate,
                      reportNDVI: singlefieldNDVI,
                    }
                  );
                }
              },
              maintainAspectRatio: false,
              scales: {
                y: {
                  min: 0,
                  max: 1,
                  stepSize: 0.1,
                },
              },
            }}
            plugins={[{
              id: 'lineChartBackground',
              beforeDraw(chart, args, pluginOptions){
                const{ctx} = chart;
                ctx.save;
                ctx.fillStyle = '#ffffff';
                ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
                ctx.restore();
              },
            }]}
          />
        </div>
        <h3 className="mowing-section-header">
          Data Table of Satellite Observations
          <button
            className="text-button"
            onClick={() => {downloadDataTable()}}
          >
            <h5>Download</h5>
          </button>
        </h3>
        <SatelliteObservations></SatelliteObservations>
      </div>
    </div>
  )
}
