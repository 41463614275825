import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import "./mowing-create-page.css";
import "./mowing-confirm-haze-page.css";

import { url, VALUATION_PORT } from '../apis/landsteward/urlconfig'
import { getMDReport } from "../apis/landsteward/accounts";
import useFetch from '../apis/landsteward/useFetch'

import closeIcon from "../images/close-popup-button.png";


export function MowingConfirmHazePage(props) {
    const params = useParams();
    const history = useHistory();

    /** CONSTANTS */
    const project = props.searchState("projects", "projectID", params.projectID);
    const [hazyImages, setHazyImages] = useState([]);

    /** FLAGS */
    const [selectedStatuses, setSelectedStatuses] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [excludeImagesClicked, setExcludeImagesClicked] = useState(false);
    const [includeAllImagesClicked, setIncludeAllImagesClicked] = useState(false);

    /** COUNTERS */
    const [selectedCount, setSelectedCount] = useState(0);

    /** FIELDS */
    const [enlargedImage, setEnlargedImage] = useState(null);

    /** RETRIEVE HAZY IMAGES */
    useEffect(() => {
        async function getImage(folder) {
            const path = `/mowing/${folder}`;

            return useFetch(url + ":" + VALUATION_PORT + path, 'GET', null, true);
        }

        function formatDateTime(dt) {
            return `${dt.substring(0, 10)} ${dt.substring(11, 16)}`;
        }

        function getSatellite(source) {
            return source[3] == 'S' ? 'Sentinel' : 'Landsat';
        }

        getMDReport(params.mowingID, 'mowing_detection').then(async (reportInfo) => {
            const hazyImageData = JSON.parse(reportInfo['hazy_images']);
            let temp = [];
            for (const id in hazyImageData) {
                temp.push({
                    'id': id,
                    'imageBase64': await getImage(hazyImageData[id]['temp_folder']),
                    'datetime': formatDateTime(hazyImageData[id]['datetime']),
                    'satellite': getSatellite(hazyImageData[id]['image_source'])
                });
            }
            setHazyImages(temp);
        });
    }, [])

    function continueReport(statuses) {
        let excludedImages = [];
        Object.keys(statuses).map((key) => {
            if (statuses[key]) {
                excludedImages.push(key)
            }
        })
        history.push(`/project/${params.projectID}/${params.mowingID}/3/mowing-${params.action}`, {"excludedImages": excludedImages});
    }

    function includeAllImages() {
        let statuses = {};
        hazyImages.map((image) => {statuses[image['id']] = false});
        setSelectAll(true);
        setSelectedStatuses(statuses);
        continueReport(statuses);
    }

    function toggleAllImagesSelected() {
        let statuses = {};
        if (selectAll) {
            setSelectAll(false);
            setSelectedCount(0);
            hazyImages.map((image) => {statuses[image['id']] = false});
        } else {
            setSelectAll(true);
            setSelectedCount(hazyImages.length);
            hazyImages.map((image) => {statuses[image['id']] = true});
        }
        setSelectedStatuses(statuses);
    }

    function toggleImageSelected(id) {
        if (selectedStatuses[id]) {
            if (selectedCount == hazyImages.length) setSelectAll(false);
            setSelectedCount((oldCount) => (oldCount - 1))
        } else {
            if (selectedCount + 1 == hazyImages.length) setSelectAll(true);
            setSelectedCount((oldCount) => (oldCount + 1))
        }
        setSelectedStatuses((oldStatuses) => ({...oldStatuses, [id]: !oldStatuses[[id]]}));
    }

    function transformImage(imageBase64) {
        const byteCharacters = atob(imageBase64['data']['image']);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return URL.createObjectURL(new Blob([byteArray], { type: 'image/png' }));
    }

    return (
        <div className="page-margins">
            <h3 className="mowing-section-header">
                Confirm Hazy Images
            </h3>
            <div className="mowing-form">
                <div className="mowing-form-project">
                    <div className="mowing-form-project-label">
                        Project:
                    </div> 
                    <div className="mowing-form-project-name">
                        {project ? project.name : ""}
                    </div>
                </div>
                <div className="mowing-form-description">
                    Please select all of the images you’d like to exclude from your report.
                </div>
                <div className="mowing-form-checkbox">
                    <button 
                        className={`mowing-form-${selectAll ? "selected" : "unselected"}`}
                        onClick={() => {toggleAllImagesSelected()}}
                    >
                    </button>
                    <div className="mowing-form-checkbox-label">
                        {selectAll ? "Deselect All" : "Select All"}
                    </div>
                </div>
                <div className="mowing-form-satellite-display">
                    {hazyImages.map((image) => {
                        return (
                            <div className="mowing-form-satellite-image">
                                <div className="mowing-form-satellite-image-checkbox">
                                    <button
                                        id={image['id']}
                                        className={`mowing-form-${selectedStatuses[image['id']] || selectAll ? "selected" : "unselected"}`}
                                        onClick={() => {toggleImageSelected(image['id'])}}
                                    >
                                    </button>
                                </div>
                                <img 
                                    src={transformImage(image['imageBase64'])}
                                    onClick={() => {if (!enlargedImage) setEnlargedImage(image)}}
                                >
                                </img>
                                <div className="mowing-form-satellite-image-details">
                                    <div className="mowing-form-details-text">
                                        Date: <span>{image['datetime']}</span>
                                        <br></br>
                                        Satellite: <span>{image['satellite']}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="mowing-form-buttons">
                    <button 
                        className={`mowing-form-dark-button${selectedCount == 0 ? "-disabled" : ""}`}
                        onClick={() => {setExcludeImagesClicked(true)}}
                        disabled={selectedCount == 0 || includeAllImagesClicked}
                    >
                        {selectedCount == 0 ? "0 Selected" : `Exclude ${selectedCount} Selected`}
                    </button>
                    <button 
                        className="mowing-form-light-button"
                        onClick={() => {setIncludeAllImagesClicked(true)}}
                        disabled={excludeImagesClicked}
                    >
                        Include All
                    </button>
                </div>
            </div>

            {/* POPUPS */}
            
            {enlargedImage && <div className="darkened-background"></div>}
            {
                enlargedImage &&
                <div className="mowing-form-popup-display">
                    <img 
                        src={closeIcon}
                        className="mowing-form-popup-display-button"
                        onClick={() => {setEnlargedImage(null)}}
                    >
                    </img>
                    <img 
                        src={transformImage(enlargedImage['imageBase64'])}
                        className="mowing-form-popup-display-image"
                    >
                    </img>
                    <div className="mowing-form-popup-display-details">
                        <div className="mowing-form-details-text">
                            Date: <span>{enlargedImage['datetime']}</span>
                        </div>
                        <div className="mowing-form-details-text">
                            Satellite: <span>{enlargedImage['satellite']}</span>
                        </div>
                    </div>
                </div>
            }
            {
                excludeImagesClicked &&
                <div className="mowing-form-popup-prompt">
                    <div className="mowing-form-popup-prompt-text">
                        <div className="mowing-form-popup-prompt-title">
                            Exclude {selectedCount} Image{selectedCount > 1 ? "s" : ""}
                        </div>
                        <div className="mowing-form-popup-prompt-details">
                            Are you sure you want to exclude these {selectedCount} images from your report?
                        </div>
                    </div>
                    <div className="mowing-form-popup-prompt-buttons">
                        <button 
                            className="mowing-form-popup-prompt-dark-button"
                            onClick={() => {continueReport(selectedStatuses)}}
                        >
                            Exclude {selectedCount} Image{selectedCount > 1 ? "s" : ""}
                        </button>
                        <button 
                            className="mowing-form-popup-prompt-light-button"
                            onClick={() => {setExcludeImagesClicked(false)}}
                        >
                            Go Back
                        </button>
                    </div>
                </div>
            }
            {
                includeAllImagesClicked &&
                <div className="mowing-form-popup-prompt">
                    <div className="mowing-form-popup-prompt-text">
                        <div className="mowing-form-popup-prompt-title">
                            Include All Images
                        </div>
                        <div className="mowing-form-popup-prompt-details">
                            Are you sure you want to include all of these images in your report anyway?
                        </div>
                    </div>
                    <div className="mowing-form-popup-prompt-buttons">
                        <button 
                            className="mowing-form-popup-prompt-dark-button"
                            onClick={includeAllImages}
                        >
                            Include All Images
                        </button>
                        <button 
                            className="mowing-form-popup-prompt-light-button"
                            onClick={() => {setIncludeAllImagesClicked(false)}}
                        >
                            Go Back
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}