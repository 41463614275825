import { url, CORE_PORT, VALUATION_PORT, MONITORING_PORT } from "./urlconfig";
import useFetch from "./useFetch";
import { useFetchBlobAPI, useFileUpload } from "./useFetch";
import useFetchCSV from "./useFetchCSV";
import { getRanHex } from "../../utils/helpers";
/**
 *
 * @param {string} name Project Name
 *      NOTE: geoJSON MUST have a "name":<string >   attribute
 * @returns projectID corresponding to such data
 */
export async function createProject(
  name,
  description,
  characteristics_keys,
  region,
  service,
  account_id,
) {
  const path = "/projects/";
  const httpMethod = "POST";
  let output = "";

  /** Create new project and  associates the geoJSON with it */

  const body = {
    name: name,
    description: description,
    characteristics_keys: JSON.stringify(characteristics_keys),
    service: service,
    users: [account_id]
  };
  if (region) {
    body.region = region;
  }

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  if (!response.hasError) {
    output = response.data.projectID;
    // console.log("CREATE PROJECT backend ", response.data);
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

/**
 *
 * @returns An object containing an array of project metadata objects,
 *      called 'project_array'
 */
export async function getProjects(account_id) {
  const path = account_id ? `/projects/acc=${account_id}/` : `/projects/`;
  const httpMethod = "GET";
  let output = "";
  // console.log("This is out account id", account_id)

  /** Retrieves all projects, will return based on user  */
  const response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data;
    // console.log(output);
  } else {
    output = null;
    // console.error(response.errorMessage);
  }

  return output;
}

/**
 *
 * @returns An object containing details of projectID
 */
export async function getProjectDetails(projectID) {
  const path = `/projects/${projectID}/`;
  const httpMethod = "GET";
  let output = "";

  /** Retrieves all projects, will return based on user  */
  const response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data.data;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

/**
 * @returns An object containing all properties of a projectID
 */
export async function getProjectProperties(projectID) {
  const path = `/projects/${projectID}/properties/`;
  const httpMethod = "GET";
  let output = "";

  /** Retrieves all projects, will return based on user  */
  const response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data.properties;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

/**
 *
 * @returns An object containing details of landowner added to the project
 */
export async function addLandownerToProject(projectID, email) {
  const path = `/projects/${projectID}/landowners/`;
  const httpMethod = "PUT";
  let output = "";

  const body = {
    email: email,
  };
  const response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

export async function createReport(
  projectID,
  regionID,
  imageID,
  name,
  spatial_res
) {
  const path = "/floodrisk/";
  const httpMethod = "PUT";
  let output = "";

  /** Create new project and  associates the geoJSON with it */
  const body = {
    projectID: projectID,
    regionID: regionID,
    imageID: imageID,
    name: name,
    spatial_res: spatial_res,
  };

  let response = await useFetch(
    url + ":" + VALUATION_PORT + path,
    httpMethod,
    body,
    true
  );
  console.log(response);

  /** Fetch JSON response processed */
  if (!response.hasError) {
    console.log("CREATE REPORT ", response.data);
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }
  console.log("CREATE REPORT OUTPUT", output);
  return output;
}


/** 
 * This is a function that fetches and retrieves the entire carbon 
 * report information (to be displayed to the user).
 */
export async function getReport(reportID, reportTypeName) {
  let path = '/reports/' + reportTypeName + '/' + reportID + '/';
  const httpMethod = 'GET';
  let output = '';

  // const body = {reportID: reportID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, null, true);
  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

// Gets all reports for a specific project and type. If type is "any" returns all reports.
export async function getAllReportsType(projectID, reportType) {
  let path = '/projects/' + projectID + '/reports/';
  const httpMethod = 'GET';
  let output = '';
  

  //const body = {reportID: reportID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, null, true);
  console.log("Response",response)

  if (response.hasError) {
    output = null;
    console.log(response.errorMessage)
  } else {
    /** Filters all reports, return array of reports with `reportType` */
    if (reportType == "any") {
      output = response.data.reports;
    } else {
      let reportData = response.data.reports.filter((report) => report.reportType === reportType);
      output = reportData;
    }
    

  }
  
  return output;
}


// GET ALL REPORTS for lulc
// Currently Not Working
export async function getAllReport(projectID, reportTypeName) {
  let path = '/projects/' + projectID + '/reports/';
  const httpMethod = 'GET';
  let output = '';
  

  //const body = {reportID: reportID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, null, true);
  console.log("PATH--",response)
  // return the floodrisk report info
  if (!response.hasError) {
    /** Filters all reports, return array of lulc reports */
    let reportData = response.data.reports.filter((report) => report.reportType === reportTypeName);

    /** Get the detailed data for each lulc report */
    const allReportData = await Promise.all(reportData.map((report) => {
      console.log(report.reportID);

      let data = getReport(report.reportID);

      if (!data.hasError) {

        return data;
      } else {
        console.log(data.errorMessage);
        return null;
      }
    }))

    output = allReportData;


  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


export async function archiveProject(projectID) {
  const path = `/projects/archive/`;
  const httpMethod = "PUT";
  let output = "";

  /** Has body of project to be archived */
  const body = { projectID: projectID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );
  

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function archiveMowingReport(reportID) {
  const path = `/reports/mowing_detection/archive/`;
  const httpMethod = "PUT";
  let output = "";

  /** Has body of report to be archived */
  const body = { reportID: reportID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );
  

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function archiveForestReport(reportID) {
  const path = `/reports/forest_detection/archive/`;
  const httpMethod = "PUT";
  let output = "";

  /** Has body of report to be archived */
  const body = { reportID: reportID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );
  

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function archiveRegion(regionID) {
  const path = `/regions/archive/`;
  const httpMethod = "PUT";
  let output = "";

  /** Has body of region to be archived */
  const body = { regionID: regionID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );
  
  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/** Archives property with corresponding regionID when region is archived */ 
export async function archivePropertyByRegion(regionID) {
  const path = `/properties/archiveByRegion/`;
  const httpMethod = "PUT";
  let output = "";

  /** Has body of property to be archived */
  const body = { regionID: regionID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/** Renames property with corresponding regionID when region is renamed */ 
export async function renamePropertyByRegion(regionID, newName) {
  const path = `/properties/renameByRegion/`;
  const httpMethod = "PUT";
  let output = "";

  const body = { regionID: regionID, newName: newName}
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

// Change the name of a forest report, including all associated ndvi reports and satellite_image reports
export async function renameForestReport(reportID, newName) {
  const forestReport = await getMDReport(reportID, "forest_detection");
  console.log(forestReport);
  console.log("Current report name: ", forestReport.name);

  const path = "/reports/forest_detection/" + reportID + "/rename/";
  const httpMethod = "PUT";
  const body = { reportID: reportID, newName: newName };
  let output = "";
  
  try {
    let response = await useFetch(
      url + ":" + CORE_PORT + path,
      httpMethod,
      body,
      true
    );
  
    if (!response.hasError) {
      output = true;
    } else {
      output = null;
      console.log(response.errorMessage);
    }
  } catch (error) {
    output = null;
    console.error("Error during renameForestReport:", error);
  }

  return output;
}

export async function renameProject(projectID, newName) {
  const path = `/projects/${projectID}/rename/`;
  const httpMethod = "PUT";
  let output = "";

  const body = { projectID: projectID, newName: newName}
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function renameRegion(regionID, newName) {
  const path = `/regions/${regionID}/rename/`;
  const httpMethod = "PUT";
  let output = "";

  const body = { regionID: regionID, newName: newName}
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function updateForestReportInfo(reportID, updatedReportInfo) {
  const path = "/reports/forest_detection/renameReportInfo/";
  const httpMethod = "PUT";
  let output = "";

  const body = {
      reportID: reportID,
      updatedReportInfo: updatedReportInfo, 
  };

  let response = await useFetch(
      url + ":" + CORE_PORT + path,
      httpMethod,
      body,
      true
  );

  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


export async function deleteProject(projectID) {
  const path = `/projects/${projectID}/`;
  const httpMethod = "DELETE";
  let output = "";

  /** Retrieves all reports based on project ID provided  */
  const body = { projectID: projectID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function deleteReport(reportID) {
  const path = "/report/delete/";
  const httpMethod = "DELETE";
  let output = "";

  /** Retrieves all reports based on project ID provided  */
  const body = { reportID: reportID };
  let response = await fetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function downloadSatelliteImage(metaObject) {
  const path = "/sentinel/download/";
  const httpMethod = "POST";
  let responseData = "";
  let output = "";

  /** Retrieves all reports based on project ID provided  */
  let response = await useFetch(
    url + ":" + MONITORING_PORT + path,
    httpMethod,
    metaObject,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data.imageID;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function getSatelliteImageDetails(imageID) {
  const path = "/reports/satellite_image/" + imageID + "/";
  const httpMethod = "GET";
  let output = "";

  /** Retrieves all reports based on project ID provided  */
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function getNDVIFromSatellite(reportID) {
  const path = "/reports/satellite_image/" + reportID + "/ndvi/";
  const httpMethod = "GET";
  let output = "";

  /** Retrieves all reports based on project ID provided  */
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function projectAddRegion(projectID, regionID) {
  const path = "/project/add/region/";
  const httpMethod = "PUT";
  let output = "";

  const body = { projectID: projectID, regionID: regionID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

// NOT WORKING
export async function getRegionData(regionID) {
  const path = `/sregions?regionID=${regionID}`;
  const httpMethod = "GET";
  let output = "";

  /** Retrieves all regions of a project, will return based on user  */

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data.regions;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/** Custom Region Functions */

export async function getAllRegions() {
  let path = "/regions/";
  const httpMethod = "GET";
  let output = "";

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/**
 * Creates a custom region by passing in the geojson formated object
 */
export async function getCustomRegions() {
  let path = "/region/custom/";
  const httpMethod = "GET";
  let output = "";

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/**
 * Creates a custom region by passing in the geojson formated object
 */
export async function getCustomRegionDetail(id) {
  let path = "/region/custom/" + id + "/";
  const httpMethod = "GET";
  let output = "";

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/**
 * Creates a custom region by passing in the geojson formated object
 */
export async function createCustomRegion(name, geojson, projectID = "None", regionType = "custom") {
  let path = "/regions/";
  const httpMethod = "POST";
  let output = "";
  if (!geojson.id) {
    geojson.id = getRanHex(32);
    //geojson.id = getRanHex(32)
  }
  const body = {
    name: name ? name : `${geojson.id}`,
    regionType: regionType,
    file: JSON.stringify(geojson),
    projectID: projectID,
  };
  console.log(body);
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function createCustomRegionShape(
  fileInput, 
  name = "TestingShptoGeoJSON", 
  projectID = "None", 
  regionType = "custom"
) {
  // Ensure fileInput is a File object before proceeding
  if (!(fileInput instanceof File)) {
    throw new Error("Invalid file input. Expected a File object.");
  }

  const path = "/regions/shp_to_geojson/";
  const httpMethod = "POST";
  
  // Prepare FormData for the zip file upload
  const formData = new FormData();
  formData.append("name", name);
  formData.append("regionType", regionType);
  formData.append("projectID", projectID);
  formData.append("file", fileInput); // Assumes fileInput is the file object from an <input type="file">

  try {
    // Send the formData to your backend
    const response = await useFileUpload(
      url + ":" + CORE_PORT + path,
      httpMethod, 
      formData, 
      true
    );

    // Check if the response is successful
    if (response.hasError) {
      throw new Error(`Server responded with status ${response.errorMessage}`);
    }

    return JSON.stringify(response.data);
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error; // Re-throw the error for handling in calling function
  }
}

export async function createCustomProperty(body) {
  let path = "/properties/";
  const httpMethod = "POST";
  let output = "";

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}
// fetches all properties
export async function getAllForestProperties() {
  let path = "/properties/";
  const httpMethod = "GET";
  let output = "";

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function getProjectSatelliteImages(projectID) {
  const path = "/projects/" + projectID + "/reports/";
  const httpMethod = "GET";
  let output = "";
  /** Retrieves all reports, will return based on project  */
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  if (!response.hasError) {
    /** Filters all reports, return array of satellite image reports */
    let reportData = response.data.reports.filter(
      (report) => report.reportType === "satellite_image"
    );
    /** Get the detailed data for each satellite image report */
    const satelliteImageData = await Promise.all(
      reportData.map((report) => {
        console.log(report.reportID);
        let satelliteData = getSatelliteImage(report.reportID);
        if (!satelliteData.hasError) {
          return satelliteData;
        } else {
          console.log(satelliteData.errorMessage);
          return null;
        }
      })
    );

    output = satelliteImageData;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function getProjectReports(projectID) {
  const path = "/project/get/report/";
  const httpMethod = "PUT";
  let output = "";

  /** Retrieves all projects, will return based on user  */
  const body = { projectID: projectID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data.reports;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function getReportImages(reportObject) {
  let reportID = reportObject.reportID;
  let reportType = reportObject.reportType;
  let path = `/report/${reportType}/get/`;
  const httpMethod = "PUT";
  let output = "";

  /** Retrieves all reports based on project ID provided  */
  const body = { reportID: reportID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data.images;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function getImageDetails(imageObject) {
  let imageID = imageObject.imageID;
  let imageType = imageObject.imageType;
  let path = `/image/${imageType}/get/`;

  const httpMethod = "PUT";
  let output = "";

  /** Retrieves all reports based on project ID provided  */
  const body = { imageID: imageID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/**
 * This is a function that fetches and retrieves the entire floodrisk
 * report information (to be displayed to the user).
 */
export async function getFloodRiskDetails(reportID) {
  let path = "/report/floodrisk/get/";
  const httpMethod = "PUT";
  let output = "";

  const body = { reportID: reportID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data.report_info;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/**
 * This is a function that fetches and retrieves the array of images
 * associated with a floodrisk report, to be used in other functions
 * to retrieve a specific image from the array.
 */
export async function getFloodRiskImages(reportID) {
  let path = "/report/floodrisk/get/";
  const httpMethod = "PUT";
  let responseDataArray = "";
  let outputArray = "";

  const body = { reportID: reportID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  // return the image array
  if (!response.hasError) {
    outputArray = response.data.images;
  } else {
    outputArray = null;
    console.log(response.errorMessage);
  }
  return outputArray;
}

/**
 * This is a function that fetches and retrieves a particular map image
 * given the array of floodrisk images and the particular index the image
 * is located at.
 */
export async function getFloodRiskMapImageFromIDX(reportID, i) {
  let path = "/image/map/get/";
  const httpMethod = "PUT";
  let output = "";
  // retrieve the image array
  const imageArray = await getFloodRiskImages(reportID);
  console.log(imageArray);

  // retrieve the image ID for the image at the requested index of the array
  let imageID = imageArray[i].imageID;

  // return the image
  const body = { imageID: imageID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/**
 * This is a function that fetches and retrieves the landcover image
 * associated with a floodrisk report, using the functions above
 * to retrieve the floodrisk image array and continue to fetch each of the images
 * in the array until the image returned's mapType is the one required (landcover).
 */
export async function getFloodRiskMapLandcoverImage(reportID) {
  // retrieve the image array
  const imageArray = await getFloodRiskImages(reportID);
  console.log(imageArray);
  // loop through the image array and continue fetching until
  // the image returned has a mapType of landcover
  for (let i = 0; i < imageArray.length; i++) {
    if (imageArray[i].imageType === "map") {
      const image = await getFloodRiskMapImageFromIDX(reportID, i);
      if (image.mapType != "landcover") continue;
      else return image;
    }
  }
  return null; // if image with required mapType cant be found
}

/**
 * This is a function that fetches and retrieves the built infrastructure image
 * associated with a floodrisk report, using the functions above
 * to retrieve the floodrisk image array and continue to fetch each of the images
 * in the array until the image returned's mapType is the one required (infra).
 */
export async function getFloodRiskMapInfraImage(reportID) {
  // retrieve the image array
  const imageArray = await getFloodRiskImages(reportID);
  console.log(imageArray);
  // loop through the image array and continue fetching until
  // the image returned has a mapType of infrastructure
  for (let i = 0; i < imageArray.length; i++) {
    if (imageArray[i].imageType === "map") {
      const image = await getFloodRiskMapImageFromIDX(reportID, i);
      if (image.mapType != "infrastructure") continue;
      else return image;
    }
  }
  return null; // if image with required mapType cant be found
}

/**
 * This is a function that fetches and retrieves the runoff image
 * associated with a floodrisk report, using the functions above
 * to retrieve the floodrisk image array and continue to fetch each of the images
 * in the array until the image returned's mapType is the one required (runoff).
 */
export async function getFloodRiskMapRunoffImage(reportID) {
  // retrieve the image array
  const imageArray = await getFloodRiskImages(reportID);
  console.log(imageArray);
  // loop through the image array and continue fetching until
  // the image returned has a mapType of runoff
  for (let i = 0; i < imageArray.length; i++) {
    if (imageArray[i].imageType === "map") {
      const image = await getFloodRiskMapImageFromIDX(reportID, i);
      if (image.mapType != "runoff_ret_idx") continue;
      else return image;
    }
  }
  return null; // if image with required mapType cant be found
}

export async function getSATImageFromIDX(projectID, i) {
  let path = "/image/satellite/get/";
  const httpMethod = "PUT";
  let output = "";
  const imageArray = await getProjectSatelliteImages(projectID);
  let imageID = imageArray[i].imageID;

  const body = { imageID: imageID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function getSatelliteImage(reportID) {
  let path = "/reports/satellite_image/" + reportID + "/";
  const httpMethod = "GET";
  let output = "";

  //Get the detailed data for a satellite image report
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }
  return output;
}

/**
 * Retrieves a true colour satellite image TIFF based on the report ID.
 * 
 * @param {string}  reportID    Report ID of the satellite image.
 * 
 * @returns {image} True colour satellite TIFF.
 */
export async function getSatDownloadTiff(reportID) {
  const path = `/reports/satellite_image/${reportID}/download_tiff/`;
  console.log("Getting true colour satellite image.")
  const response = await useFetchBlobAPI(url + ":" + CORE_PORT + path, 'GET', null, true);
  console.log(response)

  if (!response.hasError) {
      return response.blob
  } else {
      console.error("Attempt to get NDVI image failed.")
      return null
  }
}

/**
 * This is a function that fetches and retrieves the entire lulc
 * report information (to be displayed to the user).
 */
export async function getLULCReport(reportID) {
  let path = "/reports/lulc/" + reportID + "/";
  const httpMethod = "GET";
  let output = "";

  // const body = {reportID: reportID};
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );
  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

// GET ALL REPORTS for lulc
export async function getAllLULCReport(projectID) {
  let path = "/projects/" + projectID + "/reports/";
  const httpMethod = "GET";
  let output = "";

  //const body = {reportID: reportID};
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  // return the floodrisk report info
  if (!response.hasError) {
    /** Filters all reports, return array of lulc reports */
    let reportData = response.data.reports.filter(
      (report) => report.reportType === "lulc"
    );

    /** Get the detailed data for each lulc report */
    const lulcreportdata = await Promise.all(
      reportData.map((report) => {
        console.log(report.reportID);

        let lulcdata = getLULCReport(report.reportID);

        if (!lulcdata.hasError) {
          return lulcdata;
        } else {
          console.log(lulcdata.errorMessage);
          return null;
        }
      })
    );

    output = lulcreportdata;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

// MOWING DETECTION REPORTS

/**
 * This is a function that fetches and retrieves the entire lulc
 * report information (to be displayed to the user).
 */

export async function getMDReport(reportID, reportType) {
  if (reportType == 'mowing_detection') {
    var path = "/reports/mowing_detection/" + reportID + "/";
  } else {
    var path = "/reports/forest_detection/" + reportID + "/"; 
  }
  const httpMethod = "GET";
  let output = "";

  // const body = {reportID: reportID};
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );
  console.log(response);
  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    // console.log(response.errorMessage);
  }

  return output;
}

export async function getReportSatelliteImages(reportID) {
  const reportType = 'forest_detection';
  const reportData = await getMDReport(reportID, reportType);
  if (!reportData) {
    console.log('Report not found - could not fetch satellite images.');
    return null;
  }
  let satIDs = reportData.satellite_images;
  console.log('getReportSatelliteImages satIDs: ', satIDs);

  const satelliteImageData = [];

  for (const satID of satIDs) {    
    let satelliteData = await getSatelliteImage(satID);
    if (!satelliteData.hasError) {
      satelliteImageData.push(satelliteData);
    } else {
      console.log(`Error for satID ${satID}: ${satelliteData.errorMessage}`);
    }
  }

  return satelliteImageData;
}

export function download(data, name, type) {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", name + "_" + type + ".csv");
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// const objectToCsv = function(data) {
//   const csvRows = [];
//   const headers = Object.keys(data[0]);
//   csvRows.push(headers.join(','));

//   for (const row of data) {
//     const values = headers.map(header => {
//       const escaped = (''+row[header]).replace(/"/g, '\\"');
//       return `"${escaped}"`;

//     });
//     csvRows.push(values.join(','));

//   }
//   return csvRows.join('\n');
// }

export async function getMDReportCSV(reportID, reportType) {
  if (reportType == 'mowing_detection') {
    var path = "/reports/mowing_detection/" + reportID + "/csv/";
  } else {
    var path = "/reports/forest_detection/" + reportID + "/csv/"; 
  }
  //let path = "/reports/mowing_detection/" + reportID + "/csv/";
  const httpMethod = "GET";
  let output = "";

  // const body = {reportID: reportID};
  let response = await useFetchCSV(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  console.log(response);
  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data;

    // console.log(response.errorMessage);
  }

  const csvData = output;
  console.log(csvData);
  return csvData;
  // download(csvData);

}

// GET ALL REPORTS for lulc
export async function getAllMDReport(projectID, reportType) {
  let path = "/projects/" + projectID + "/reports/";
  const httpMethod = "GET";
  let output = "";

  //const body = {reportID: reportID};
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  // return the floodrisk report info
  if (!response.hasError) {
    /** Filters all reports, return array of mowing detection reports */
    let reportData = response.data.reports.filter(
        (report) => report.reportType === reportType
    );

    /** Get the detailed data for each lulc report */
    const mdreportdata = await Promise.all(
      reportData.map((report) => {
        console.log(report.reportID);

        let mddata = getMDReport(report.reportID, reportType);

        if (!mddata.hasError) {
          return mddata;
        } else {
          console.log(mddata.errorMessage);
          return null;
        }
      })
    );

    output = mdreportdata;
  } else {
    output = null;
    console.log(response.errorMessage);
  }
  return output;
}

/**
 * This is a function that fetches and retrieves the entire lulc
 * report information (to be displayed to the user).
 */
export async function getLULCDetails(reportID) {
  let path = "/report/lulc/get/";
  const httpMethod = "PUT";
  let output = "";

  const body = { reportID: reportID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data.report_info;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/**
 * This is a function that fetches and retrieves the entire lulc
 * report information (to be displayed to the user).
 */
export async function getLULCSatelliteImage(reportID) {
  let path = "/report/lulc/get/";
  const httpMethod = "PUT";
  let output = "";

  const body = { reportID: reportID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data.satellite_image;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/**
 * This is a function that fetches and retrieves the array of images
 * associated with a lulc report, to be used in other functions
 * to retrieve a specific image from the array.
 */
export async function getLULCImages(reportID) {
  let path = "/report/lulc/get/";
  const httpMethod = "PUT";
  let outputArray = "";

  const body = { reportID: reportID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  // return the image array
  if (!response.hasError) {
    console.log("LULC images ok");
    outputArray = response.data.images;
  } else {
    outputArray = null;
    console.log(response.errorMessage);
  }

  return outputArray;
}

/**
 * This is a function that fetches and retrieves a particular map image
 * given the array of lulc images and the particular index the image
 * is located at.
 */
export async function getLULCMapImageFromIDX(reportID, i) {
  let path = "/image/map/get/";
  const httpMethod = "PUT";
  let output = "";
  // retrieve the image array
  const imageArray = await getLULCImages(reportID);
  console.log(imageArray);

  // retrieve the image ID for the image at the requested index of the array
  let imageID = imageArray[i].imageID;

  // return the image
  const body = { imageID: imageID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/**
 * This is a function that fetches and retrieves the landcover image
 * associated with a lulc report, using the functions above
 * to retrieve the lulc image array and continue to fetch each of the images
 * in the array until the image returned's mapType is the one required (landcover).
 */
export async function getLULCMapLandcoverImage(reportID) {
  // retrieve the image array
  const imageArray = await getLULCImages(reportID);
  console.log(imageArray);
  // loop through the image array and continue fetching until
  // the image returned has a mapType of landcover
  for (let i = 0; i < imageArray.length; i++) {
    if (imageArray[i].imageType === "map") {
      const image = await getLULCMapImageFromIDX(reportID, i);
      if (image.mapType != "landcover") continue;
      else return image;
    }
  }
  return null; // if image with required mapType cant be found
}

/** THE FOLLOWING FUNCTIONS ARE USED FOR THE AUTOMATIC CHANGE DETECTION DISPLAY PAGE */

/**
 * Gets all the reports, then filters for lulcautodeteection reports only, then gets each report details.
 * NEEDS TO BE OPTIMIZED. ADD NEW BACKEND API.
 * CURRENTLY ONLY FOR LULCAUTODETECTION
 * @param {string} projectID
 * @returns List of autochange reports for the projectID. Return null if projectID does not exist.
 */

export async function getProjectAutoDetection(projectID) {
  const path = "/projects/" + projectID + "/reports/";
  const httpMethod = "GET";
  let output = "";
  /** Retrieves all reports, will return based on project  */
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  if (!response.hasError) {
    /** Filters all reports, return array of lulc autodetection reports */
    let reportData = response.data.reports.filter(
      (report) => report.reportType === "lulcautodetection"
    );
    /** Get the detailed data for each report */
    const lulcAutoDetectionReports = await Promise.all(
      reportData.map((report) => {
        console.log(report.reportID);
        let autoDetectionData = getAutoDetection(report.reportID);
        if (!autoDetectionData.hasError) {
          return autoDetectionData;
        } else {
          console.log(autoDetectionData.errorMessage);
          return null;
        }
      })
    );

    output = lulcAutoDetectionReports;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/**
 * Gets detailed information for a lulcautodetection report
 * @param {string} reportID
 * @returns json object of lulcautodetection report details
 */
export async function getAutoDetection(reportID) {
  let path = "/reports/lulcautodetection/" + reportID + "/";
  console.log(path);
  const httpMethod = "GET";
  let output = "";

  //Get the detailed data for a satellite image report
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }
  return output;
}
/**
 * This is a function that fetches and retrieves the array of images
 * associated with an automatic change detection report, to be used in other functions
 * to retrieve a specific image from the array.
 */
export async function getAutoDetectionImages(reportID) {
  let path = "/report/autodetection/get/";
  const httpMethod = "PUT";
  let outputArray = "";

  const body = { reportID: reportID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  // return the image array
  if (!response.hasError) {
    outputArray = response.data.images;
  } else {
    outputArray = null;
    console.log(response.errorMessage);
  }
  return outputArray;
}

/* This is a function that fetches and retrieves first/intial LULC reportID used
 * to create the automatic change detection report.
 */
export async function getACDFirstLULCID(reportID) {
  let path = "/report/autodetection/get/";
  const httpMethod = "PUT";
  let output = "";

  const body = { reportID: reportID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  // return the image array
  if (!response.hasError) {
    output = response.data.init_lulcID;
    console.log("entered report", output);
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/* This is a function that fetches and retrieves second/final LULC reportID used
 * to create the automatic change detection report.
 */
export async function getACDSecondLULCID(reportID) {
  let path = "/report/autodetection/get/";
  const httpMethod = "PUT";
  let output = "";

  const body = { reportID: reportID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  // return the image array
  if (!response.hasError) {
    output = response.data.final_lulcID;
    console.log("entered report", output);
  } else {
    output = null;
    console.log(response.errorMessage);
  }
  return output;
}

/* This is a function that fetches the first/intial LULC report used
 * to create the automatic change detection report and retrieves the satellite image
 * from that report.
 */
export async function getACDFirstSatImage(reportID) {
  let path = "/report/autodetection/get/";
  let responseData = "";
  const httpMethod = "PUT";
  let output = "";

  const body = { reportID: reportID };
  let response = await useFetch(url + path, httpMethod, body, true);

  // return the image array
  if (!response.hasError) {
    responseData = response.data.init_lulcID;
    if (responseData) {
      const report = await getLULCSatelliteImage(responseData);
      if (report) responseData = report;
    }
    output = responseData;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/* This is a function that fetches the second/final LULC report used
 * to create the automatic change detection report and retrieves the satellite image
 * from that report.
 */
export async function getACDSecondSatImage(reportID) {
  let path = "/report/autodetection/get/";
  const httpMethod = "PUT";
  let responseData = "";
  let output = "";

  const body = { reportID: reportID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  // return the image array
  if (!response.hasError) {
    responseData = response.data.final_lulcID;
    if (responseData) {
      const report = await getLULCSatelliteImage(responseData);
      if (report) responseData = report;
    }
    output = responseData;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/**
 * This is a function that fetches and retrieves a particular map image
 * given the array of ACD images and the particular index the image
 * is located at.
 */
export async function getAutoImageFromIDX(reportID, i) {
  let path = "/image/map/get/";
  const httpMethod = "PUT";
  let output = "";
  // retrieve the image array
  const imageArray = await getAutoDetectionImages(reportID);
  // console.log(imageArray);

  // retrieve the image ID for the image at the requested index of the array
  let imageID = imageArray[i].imageID;

  const body = { imageID: imageID };

  // return the image
  let response = await useFetch(url + path, httpMethod, body, true);
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/**
 * This is a function that fetches and retrieves the change image
 * associated with an ACD report, using the functions above
 * to retrieve the ACD image array and continue to fetch each of the images
 * in the array until the image returned's name is the one required (Initial Land Cover).
 */
export async function getACDFirstImage(reportID) {
  // retrieve the image array
  const imageArray = await getAutoDetectionImages(reportID);
  // console.log(imageArray);
  // loop through the image array and continue fetching until
  // the image returned has a name of Initial Land Cover
  for (let i = 0; i < imageArray.length; i++) {
    const image = await getAutoImageFromIDX(reportID, i);
    if (image.name != "Initial Land Cover") continue;
    else return image;
  }
  return null; // if image with required name cant be found
}

/**
 * This is a function that fetches and retrieves the change image
 * associated with an ACD report, using the functions above
 * to retrieve the ACD image array and continue to fetch each of the images
 * in the array until the image returned's name is the one required (Final Land Cover).
 */
export async function getACDSecondImage(reportID) {
  // retrieve the image array
  const imageArray = await getAutoDetectionImages(reportID);
  console.log(imageArray);
  // loop through the image array and continue fetching until
  // the image returned has a name of Final Land Cover
  for (let i = 0; i < imageArray.length; i++) {
    const image = await getAutoImageFromIDX(reportID, i);
    if (image.name != "Final Land Cover") continue;
    else return image;
  }
  return null; // if image with required name cant be found
}

/**
 * This is a function that fetches and retrieves the change image
 * associated with an ACD report, using the functions above
 * to retrieve the ACD image array and continue to fetch each of the images
 * in the array until the image returned's name is the one required (Changes Detected).
 */
export async function getACDChangeImage(reportID) {
  // retrieve the image array
  const imageArray = await getAutoDetectionImages(reportID);
  console.log(imageArray);
  // loop through the image array and continue fetching until
  // the image returned has a name of Changes Detected
  for (let i = 0; i < imageArray.length; i++) {
    const image = await getAutoImageFromIDX(reportID, i);
    if (image.name != "Changes Detected") continue;
    else return image;
  }
  return null; // if image with required name cant be found
}
/** -------------------------------------------------------------------------------- */

/** THE FOLLOWING FUNCTIONS ARE USED FOR THE AUTOMATIC CHANGE DETECTION CREATION PAGE */
/**
 * This is a function that fetches and retrieves the entire lulc
 * report information (to be displayed to the user).
 */
export async function getLULCLandcoverImageforACD(reportID) {
  let path = "/report/lulc/get/";
  const httpMethod = "PUT";
  let output = "";

  const body = { reportID: reportID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data.images[0];
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/** -------------------------------------------------------------------------------- */
// Notifications/ recent activities
/** -------------------------------------------------------------------------------- */

export async function getProjectNotifications(account_id) {
  let projectsList = await getProjects(account_id);
  let notification_list = [];
  projectsList.forEach((project) => {
    getProjectRecentActivity(project.projectID).then((res) => {
      let projNotifs = [];
      res.forEach((res2) => {
        const notif = {...res2, name: project.name}
        projNotifs.push(notif)
      })
      notification_list.push(projNotifs)
    }); 
  })
  return notification_list
}
    
    
  
/* TODO WHEN ADDING READ FUNCTIONALITY TO NOTIFICATIONS */
// export async function postProjectActivity(landowner, title, content, project) {
//   let path = `/projects/notifications/`;
//   const httpMethod = "POST";
//   let output = "";

//   const body = {
//     landowner: landowner,
//     title: title,
//     content: content,
//     project: project,
//   }

//   console.log(url + ":" + CORE_PORT + path)

//   let response = await useFetch(
//     url + ":" + CORE_PORT + path,
//     httpMethod,
//     body,
//     true
//   );

//   if (!response.hasError) {
//     output = response.data;
//   } else {
//     output = null;
//     console.error(response.errorMessage);
//   }

//   return output;
// }


export async function postProjectActivityRead(landowner, title, content, project) {
  let path = `/projects/notifications/`;
  const httpMethod = "PATCH";
  let output = "";

  const body = {
    landowner: landowner,
    title : title,
    content : content,
    project : project,
  }

  console.log(url + ":" + CORE_PORT + path)

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );

  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

export async function getProjectRecentActivity(projectID) {
  let path = `/projects/${projectID}/notifications/`;
  const httpMethod = "GET";
  let output = [];

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );
  if (!response.hasError) {
    output = response.data.notifications;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

export async function getLandownerRecentActivity(projectID, landownerID) {
  let path = `/projects/${projectID}/landowners/${landownerID}/`;
  const httpMethod = "GET";
  let output = [];

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );
  if (!response.hasError) {
    output = response.data.notifications;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

/** -------------------------------------------------------------------------------- */
// Landowners and Properties
/** -------------------------------------------------------------------------------- */

export async function getLandowners(projectID) {
  let path = `/projects/${projectID}/landowners/`;
  const httpMethod = "GET";
  let output = "";

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );
  if (!response.hasError) {
    output = response.data.landowners;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

export async function getLandownerDetails(projectID, landownerID) {
  let path = `/projects/${projectID}/landowners/${landownerID}/`;
  const httpMethod = "GET";
  let output = "";

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

export async function getPropertiesForLandowner(landowner_pk) {
  let path = `/users/profiles/${landowner_pk}/properties/`;
  const httpMethod = "GET";
  let output = "";

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );
  if (!response.hasError) {
    output = response.data.properties;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

export async function createPropertyForLandowner(landowner_pk, body) {
  let path = `/users/profiles/${landowner_pk}/properties/`;
  const httpMethod = "POST";
  let output = "";

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

export async function archiveProperty(landowner_pk, propertyID) {
  let path = `/users/profiles/${landowner_pk}/properties/`;
  const httpMethod = "PUT";
  let output = "";

  /** Has body of report to be archived */
  const body = { propertyID: propertyID };
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );
  

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}
/** -------------------------------------------------------------------------------- */
// New regions
/** -------------------------------------------------------------------------------- */

export async function getDataForRegion(regionID) {
  let path = `/regions/${regionID}/`;
  const httpMethod = "GET";
  let output = "";

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    // console.error(response.errorMessage);
  }

  return output;
}

export async function getProjectRegions(projectID) {
  let path = `/regions/by_project/?projectID=${projectID}`;
  const httpMethod = "GET";
  let output = "";

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
  }
  
  return output;
}

// Get the top-left and bottom-right coordinates of a region
export async function getRegionBox(regionID) {
  let path = `/regions/${regionID}/box/`;
  const httpMethod = "GET";
  let output = "";

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.error(response.errorMessage);
  }
}

/** -------------------------------------------------------------------------------- */
// Observations
/** -------------------------------------------------------------------------------- */
export async function getObservationsForProjects(projectID) {
  let path = `/projects/${projectID}/reports/`;
  const httpMethod = "GET";
  let output = [];

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );
  if (!response.hasError) {
    output = response.data.reports;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}
export async function getObservationsForLandowner(projectID, landownerID) {
  let path = `/projects/${projectID}/landowners/${landownerID}/`;
  const httpMethod = "GET";
  let output = [];

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );
  if (!response.hasError) {
    output = response.data.reports;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

export async function getFieldObservationDetail(reportID) {
  let path = `/reports/field_observation/${reportID}/`;
  const httpMethod = "GET";
  let output = [];

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );
  if (!response.hasError) {
    output = response.data;
    if (output.images) {
      output.images.forEach(
        (image, i) => (output.images[i] = url + ":" + CORE_PORT + image)
      );
    }
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}
/** -------------------------------------------------------------------------------- */
// Profiles
/** -------------------------------------------------------------------------------- */

export async function createLandownerProfile(body) {
  let path = `/users/profiles/`;
  const httpMethod = "POST";
  let output = "";

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    body,
    true
  );
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

export async function getAllUserEmails() {
  let path = `/users/profiles/`;
  const httpMethod = "GET";
  let output = [];

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );
  if (!response.hasError) {
    let tempOut = response.data;
    output = tempOut.map(address => address.email);
  } else {
    output = null;
    console.log(response.errorMessage);
  }
  return output;
}
 
export async function getAllLandowners(projectID) {
  let path = ``;
  const httpMethod = "GET";
  let output = [];

  // should get a list of profiles that dont belong to the give  project

  return output;
}
/** API calls for NDVI reports--------------------------------- */
// TODO: update api

/**
 * This is a function that fetches and retrieves the entire ndvi
 * report information (to be displayed to the user).
 */
export async function getNDVIReport(reportID) {
  let path = '/reports/ndvi/' + reportID + '/';
  const httpMethod = 'GET';
  let responseData = '';
  let output = '';

  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );
  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

// get all ndvi reports

export async function getAllNDVIReport(projectID) {
  let path = "/projects/" + projectID + "/reports/";
  const httpMethod = "GET";
  let output = "";

  //const body = {reportID: reportID};
  let response = await useFetch(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  // return the floodrisk report info
  if (!response.hasError) {
    /** Filters all reports, return array of ndvi reports */
    let reportData = response.data.reports.filter(
      (report) => report.reportType === "ndvi"
    );

    /** Get the detailed data for each ndvi report */
    const ndvireportdata = await Promise.all(
      reportData.map((report) => {
        console.log(report.reportID);

        let ndvidata = getNDVIReport(report.reportID);

        if (!ndvidata.hasError) {
          return ndvidata;
        } else {
          console.log(ndvidata.errorMessage);
          return null;
        }
      })
    );

    output = ndvireportdata;
    console.log("%%%%%%%%%%%%");
    console.log(output);
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/**
 * This is a function that fetches and retrieves the entire ndvi
 * report information (to be displayed to the user).
 */
export async function getNDVIDetails(reportID) {
  let path = '/report/ndvi/get/';
  const httpMethod = 'PUT';
  let responseData = '';
  let output = '';

  let options = {
    method: httpMethod,
    mode: "cors",
    "Content-Type": "application/json",
    body: JSON.stringify({ reportID: reportID }),
  };

  let httpResponse = await fetch(url + ":" + CORE_PORT + path, options);

  // return the ndvi report info
  if (httpResponse.ok) {
    await httpResponse.json().then((data) => {
      responseData = data.report_info;
    });
    output = responseData;
  } else {
    output = null;
  }
  return output;
}

/**
 * This is a function that fetches and retrieves the entire ndvi
 * report information (to be displayed to the user).
 */
export async function getNDVISatelliteImage(reportID) {
  let path = '/report/ndvi/get/';
  const httpMethod = 'PUT';
  let responseData = '';
  let output = '';

  let options = {
    method: httpMethod,
    mode: "cors",
    "Content-Type": "application/json",
    body: JSON.stringify({ reportID: reportID }),
  };

  let httpResponse = await fetch(url + ":" + CORE_PORT + path, options);

  // return the ndvi report info(satellite image)
  if (httpResponse.ok) {
    await httpResponse.json().then((data) => {
      console.log(data);
      responseData = data.satellite_image;
    });
    output = responseData;
  } else {
    output = null;
  }
  return output;
}

/**
 * This is a function that fetches and retrieves the array of images
 * associated with a ndvi report, to be used in other functions
 * to retrieve a specific image from the array.
 */
export async function getNDVIImages(reportID) {
  let path = "/report/ndvi/get/";
  const httpMethod = "PUT";
  let responseDataArray = "";
  let outputArray = "";

  let options = {
    method: httpMethod,
    mode: "cors",
    "Content-Type": "application/json",
    body: JSON.stringify({ reportID: reportID }),
  };

  let httpResponse = await fetch(url + ":" + CORE_PORT + path, options);

  // return the image array
  if (httpResponse.ok) {
    console.log("NDVI images ok");
    await httpResponse.json().then((data) => {
      console.log(data);
      responseDataArray = data.images;
    });
    outputArray = responseDataArray;
  } else {
    outputArray = null;
  }
  return outputArray;
}

/**
 * This is a function that fetches and retrieves a particular map image
 * given the array of ndvi images and the particular index the image
 * is located at.
 */
export async function getNDVIMapImageFromIDX(reportID, i) {
  let path = '/image/map/get/';
  const httpMethod = 'PUT';
  let response = '';
  let output = '';
  // retrieve the image array
  const imageArray = await getNDVIImages(reportID);
  console.log(imageArray);

  // retrieve the image ID for the image at the requested index of the array
  let imageID = imageArray[i].imageID;

  let options = {
    method: httpMethod,
    mode: "cors",
    "Content-Type": "application/json",
    body: JSON.stringify({ imageID: imageID }),
  };

  // return the image
  let httpResponse = await fetch(url + ":" + CORE_PORT + path, options);
  if (httpResponse.ok) {
    await httpResponse.json().then((data) => {
      response = data;
    });
    output = response;
  } else {
    output = null;
  }
  return output;
}

/**
 * This is a function that fetches and retrieves the landcover image
 * associated with a ndvi report, using the functions above
 * to retrieve the ndvi image array and continue to fetch each of the images
 * in the array until the image returned's mapType is the one required (landcover).
 */
export async function getNDVIMapOutputImage(reportID) {
  // retrieve the image array
  const imageArray = await getNDVIImages(reportID);
  console.log(imageArray);
  // loop through the image array and continue fetching until
  // the image returned has a mapType of landcover
  for (let i = 0; i < imageArray.length; i++) {
    if (imageArray[i].imageType === "map") {
      const image = await getNDVIMapImageFromIDX(reportID, i);
      //if (image.mapType != 'landcover') continue;
      //else {
      return image;
      //}
    }
  }
  return null; // if image with required mapType cant be found
}

/**
 * Retrieves an NDVI TIFF based on NDVI report ID.
 * 
 * @param {string}  ndviID    Report ID of the NDVI image.
 * 
 * @returns {image} NDVI TIFF.
 */
export async function getNDVIDownloadTiff(ndviID) {
  const path = `/reports/ndvi/${ndviID}/download_tiff/`;
  console.log("Getting NDVI image.")
  const response = await useFetchBlobAPI(url + ":" + CORE_PORT + path, 'GET', null, true);
  console.log(response)

  if (!response.hasError) {
      return response.blob
  } else {
      console.error("Attempt to get NDVI image failed.")
      return null
  }
}

/** Helper function to create a "generating" mowing report at the start of report creation
 * 
 * @returns the reportID if the initial report creation was successful
 * @returns null if not successful
 */
export async function MakeInitialReport( 
  name,
  projectID,
  startDate,
  endDate,
  spatialResolution,
  cloudMask,
  reportType
) {
  if (reportType == 'ForestDetection') {  
    var path = `/reports/forest_detection/`;
  }
  else {
    var path = `/reports/mowing_detection/`
  }
  const httpMethod = "POST";

  const body = ({
      'name': name,
      'project': projectID,
      'properties': [],
      'start_date': startDate + 'T00:00:00Z',
      'end_date': endDate +'T23:59:59Z',
      'spatial_res': spatialResolution,
      'satellite_images': [],
      'ndvi_images': [],
      'report_info': "generating",
      'cloud_mask': cloudMask,
  })

  console.log(name, projectID, startDate, endDate)

  const response = await useFetch(
      url + ":" + CORE_PORT + path,
      httpMethod,
      body,
      true
    );
  
  if (response.hasError === false) {
      console.log(response);
      return response.data.reportID;
  } else {
      console.error('Initial Report Generation Failed');
      console.error(response.errorMessage)
      return null
  }
}


/** Helper function to change the report_info field of a failed report to "failed"
* 
* @param reportID 
*/
export async function ChangeToFailed(reportID) {
  const path = `/reports/mowing_detection/${reportID}/`;
  const httpMethod = "PUT";
  const body = {
      'report_info': "failed",
    };

  const response = await useFetch(
      url + ":" + CORE_PORT + path,
      httpMethod,
      body,
      true
    );

  /** Fetch JSON response processed into a data object in JS*/
  if (response.hasError) {
      console.error("Attempt to change report_info to 'failed' was unsuccessful.");
      console.error(response.errorMessage);
  }
  else {
      console.log("Successfully changed report_info to 'failed'!")
  }
}


export async function getFCWEventsCSV(reportID) {
  let path = "/reports/forest_detection/" + reportID + "/detected_csv/";
  const httpMethod = "GET";
  let output = "";

  // const body = {reportID: reportID};
  let response = await useFetchCSV(
    url + ":" + CORE_PORT + path,
    httpMethod,
    null,
    true
  );

  console.log(response);
  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data;

    // console.log(response.errorMessage);
  }

  const csvData = output;
  console.log(csvData);
  return csvData;
  // download(csvData);

}

/**------------------------------------------------------------ */
