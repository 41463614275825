import React, { useEffect, useRef, useState } from "react";

import { useParams, Redirect } from "react-router";

import polylabel from "polylabel";
import {
  getProjectSatelliteImages,
  getReportSatelliteImages,
  getNDVIFromSatellite,
  getNDVIReport,
  getMDReport,
  getReport
} from "../apis/landsteward/accounts";
import { createNDVI } from "../apis/landsteward/valuation";
import {useFetchBlob} from "../apis/landsteward/useFetch.js";

import { MapboxCompareSlider } from "../components/mapbox/mapbox-compare";
import mapboxgl, { LngLat } from "mapbox-gl";

import "./manual-change-detection-page.css";
import RadioGroup from "../components/modules/radio-group";
import { DropdownSearch } from "../components/modules/dropdown-search";
import { useLocation } from "react-router-dom";

import {
  getLandowners,
  getDataForRegion,
  getPropertiesForLandowner,
} from "../apis/landsteward/accounts";

import DownloadIcon from "../images/download-icon.png"

/**
 *
 * @param {*} props
 */
export function ManualChangeDetectionPage(props) {
  /** ##### URL Param Check ##### */
  let params = useParams(); // parameters from url, in this case projectID and propertyID
  const [projectFailed, setProjectFailed] = useState(false); // flag to indicate if project failed to load
  /** Satellite Images */
  const [satelliteImages, setSatelliteImages] = useState(null); // all satellite images for the project
  const [numAvailableImages, setNumAvailableImages] = useState(0); // the number of dates with an available image for the report
  const [leftSatImage, setLeftSatImage] = useState(null); // satellite image report id in the left map
  const [rightSatImage, setRightSatImage] = useState(null); // satellite image report id in the right map
  /** NDVI Report IDs*/
  const [ndviLeft, setNdviLeft] = useState(null); // NDVI report id for the left map
  const [ndviRight, setNdviRight] = useState(null); // NDVI report id for the right map
  const [oldNDVILeft, setOldNDVILeft] = useState(""); // previous NDVI report id for the left map
  const [oldNDVIRight, setOldNDVIRight] = useState(""); // previous NDVI report id for the right map
  /** Landowners Info */
  const [landowners, setLandowners] = useState(null); // all landowners for the project
  /** Region Info */
  const [rawProperties, setRawProperties] = useState([]); // property information without the coordinates
  const [parsedProperties, setParsedProperties] = useState([]); // property information with the coordinates
  /** Property Layer IDs */
  const [neutralIDs, setNeutralIDs] = useState([]); // ID when the boundary hasn't been selected
  const [selectedIDs, setSelectedIDs] = useState([]); // ID when the boundary has been selected
  const [highlightedIDs, setHighlightedIDs] = useState([]); // ID when the boundary is being hovered over
  const [outlineIDs, setOutlineIDs] = useState([]); // ID for the outline specifically (this is how I set the thickness of the boundary)
  /** Mapbox Reference to left and right maps */
  const beforeMap = useRef(null); // mapbox reference to left map
  const afterMap = useRef(null); // mapbox reference to right map
  const compare = useRef(null); // mapbox reference to compare component that shows the two maps side by side
  /** MISC. Status Checks*/
  const [trueColour, setTrueColour] = useState(false); // flag to indicate if the map is in true colour (satellite image) mode
  const [showBoundaries, setShowBoundaries] = useState(true); // flag to indicate if property boundaries are being shown
  const [downloadStatus, setDownloadStatus] = useState(""); // flag to indicate id of NDVI report that needs to be downloaded
  const [loadedLandowners, setLoadedLandowners] = useState(false); // flag to indicate if landowners have been loaded
  const isMountedLeft = useRef(false); // flag to indicate if left map is mounted (if false, the map is on its first render)
  const isMountedRight = useRef(false); // flag to indicate if right map is mounted (if false, the map is on its first render)
  /** Passing radio selection to dropdown display */
  const [dropdownDisplay, setDropdownDisplay] = useState("reportID"); // flag to indicate if dropdown should display reportID, name, or date
  /** Properties gleaned from link with mowing display page chart */
  const location = useLocation(); // location object that represents current url
  const [clickedFromChart, setClickedFromChart] = useState(false); // flag to indicate if the page was clicked from the mowing display page chart
  const [satIDFromChart, setSatIDFromChart] = useState(null); // satellite image report id that was clicked from the mowing display page chart
  const [selectedField, setSelectedField] = useState(null) // field name that was clicked from the mowing display page chart
  const [lat, setLat] = useState(null); // latitude of the satellite image that was clicked from the mowing display page chart
  const [long, setLong] = useState(null); // longitude of the satellite image that was clicked from the mowing display page chart
  /** Fullscreen toggle */
  const [isFullScreen, setFullScreen] = React.useState(false); // flag to indicate if the map is in fullscreen mode

  // INITIALIZATION

  /**
   * Once the component mounts, initialize webstate of web application by loading in the project data
   *
   * */
  useEffect(() => {
    async function initializeState() {
      /*       let isCorrectState = await props.changeCurrentState(params.projectID);
            if (!isCorrectState.project) {
              console.error('Failing Projects', isCorrectState);
              setProjectFailed(true);
            } */
      let success = await props.changeProject(params.projectID);
      if (!success) {
        setProjectFailed(true);
        return;
      }
    }
    initializeState().catch(console.error);
  }, []);

  /**
   * Once project data is loaded in, load in region data
   *
   * */
  useEffect(() => {
    async function setCurrRegion() {
      if (props.project != null) {
        console.log(props.project);
        console.log("calling changeRegion()");
        let success = await props.changeRegion(props.project.region);
        if (!success) {
          console.log("Region failed to be set");
        }
      }
    }
    setCurrRegion().catch(console.error);
  }, [props.project]);

  /**
   * Once the region is loaded in, center the map on the region centroid
   * Do this if the user entered the page from the Manual Compare button  
   * OR from the graph but the centroid of the property hasn't finished computing yet
   */
  useEffect(() => {
    if ((!clickedFromChart || clickedFromChart && !location.state.centroid) && props.region != null) {
      let regionCenter = props.computeCentroid(
        props.region.data.geometry.coordinates[0]
      );
      beforeMap.current.flyTo({
        center: [regionCenter[0], regionCenter[1]],
        zoom: 10,
      });
      afterMap.current.flyTo({
        center: [regionCenter[0], regionCenter[1]],
        zoom: 10,
      });
    }
  }, [props.region]);

  // SATELLITE IMAGES

  /**
   *  Once the component mounts, fetch Satellite images
   *
   * */
  useEffect(() => {
    const fetchSatelliteImages = async () => {
      let satelliteImageData = await getProjectSatelliteImages(params.projectID);
      
      // filter out null items
      satelliteImageData = satelliteImageData.filter(item => {
        return item !== null;
      });

      // filter out duplicate times
      const seenDates = new Set();
      satelliteImageData = satelliteImageData.filter(item => {
        const duplicate = seenDates.has(item.image_date);
        seenDates.add(item.image_date);
        return !duplicate;
      });

      // filter out dates outside of report date range
      const reportDateRange = new Set(location.state.reportDates);
      satelliteImageData = satelliteImageData.filter(item => {
        return reportDateRange.has(item.image_date.slice(0, 10));
      });

      // initialize satellite image date as selected date from NDVI graph
      setSatIDFromChart(satelliteImageData[location.state.satIDIndex].reportID);
      changeLeftSat(satIDFromChart);
      changeRightSat(satIDFromChart);

      // reorder items with satellite/NDVI images to be listed first
      const reportNDVI = location.state.reportNDVI;
      let hasData = [];
      let hasNoData = [];
      reportNDVI.forEach((value, index) => {
        if (value !== 'NaN') {
          hasData.push(index);
        } else {
          hasNoData.push(index);
        }
      });
      let reorderedSatelliteImageData = [];
      hasData.forEach(index => {
        reorderedSatelliteImageData.push(satelliteImageData[index]);
      })
      hasNoData.forEach(index => {
        reorderedSatelliteImageData.push(satelliteImageData[index]);
      })
      setSatelliteImages(reorderedSatelliteImageData);
      setNumAvailableImages(hasData.length);
    };

    fetchSatelliteImages();

    //clean state on unmount, use to avoid warning
    return () => setSatelliteImages({});
  }, []);

  async function downloadHandler(side) {
    let response = null;
    let date;
    
    if (side === 'left') {
      if (leftSatImage == null){
        window.alert("Please select a date and time for Satellite Image #1");
      } else {
        date = leftSatImage['image_date'].substring(0, 19);
        response = await getNDVIReport(ndviLeft);
      }
    } else {
      if (rightSatImage == null) {
        window.alert("Please select a date and time for Satellite Image #2");
      } else {
        date = rightSatImage['image_date'].substring(0, 19);
        response = await getNDVIReport(ndviRight);
      }
    }
    
    if (response != null) {
      if (response['image_tiff'] == null) {
        window.alert("No image to download for this date, please select another date or run the report again");
      } else {
        let allOutput = await useFetchBlob(response['image_tiff']);
        if (allOutput !== null) {
          const url = window.URL.createObjectURL(allOutput.blob);
          const a = document.createElement('a');
          a.setAttribute('hidden', '');
          a.setAttribute('href', url);
          a.setAttribute('download', `${response['name']}_ndvi_${date}.tiff`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    }
  }

  /**
   * Changes the left satellite image to the one specified by reportID
   *
   * */
  const changeLeftSat = (satID) => {
    if (!satelliteImages) {
      console.error("No satellite images exist.");
      return null;
    }
    try {
      for (let i = 0; i < satelliteImages.length; i++) {
        if (satelliteImages[i].reportID === satID) {
          /** New satellite image ID */
          let newSatID = satelliteImages[i].reportID.replace(/-/g, "");
          /** Find old left satellite image ID and remove its layers */
          removeLeftSat();
          /** Add Mapbox source and layer for next satellite image */
          if (!beforeMap.current.getSource(newSatID)) {
            beforeMap.current.addSource(newSatID, {
              type: "raster",
              url: "mapbox://korotu-conroy-trinh." + newSatID,
            });
            beforeMap.current.addLayer({
              id: newSatID,
              source: newSatID,
              type: "raster",
            });
          }
          setLeftSatImage(satelliteImages[i]);
          movePropertiesToTop(beforeMap.current);
          break;
        }
      }
    } catch (error) {
      window.alert("There was a problem displaying the selected image. Please try another one.");
    }
  };

  /**
   * Changes the right satellite image to the one specified by reportID
   *
   * */
  const changeRightSat = (satID) => {
    if (!satelliteImages) {
      console.error("No satellite images exist.");
      return null;
    }
    try {
      for (let i = 0; i < satelliteImages.length; i++) {
        if (satelliteImages[i].reportID === satID) {
          /** New satellite image ID */
          let newSatID = satelliteImages[i].reportID.replace(/-/g, "");
          /** Find old right satellite image ID and remove its layers */
          removeRightSat();
          /** Add Mapbox source and layer for next satellite image */
          if (!afterMap.current.getSource(newSatID)) {
            afterMap.current.addSource(newSatID, {
              type: "raster",
              url: "mapbox://korotu-conroy-trinh." + newSatID,
            });
            afterMap.current.addLayer({
              id: newSatID,
              source: newSatID,
              type: "raster",
            });
          }
          setRightSatImage(satelliteImages[i]);
          movePropertiesToTop(afterMap.current);
          break;
        }
      }
    } catch (error) {
      window.alert("There was a problem displaying the selected image. Please try another one.");
    }
  };

  /**
   * Removes the left satellite image from being display on the map
   *
   * */
  const removeLeftSat = () => {
    /** Previous satellite image ID */
    let oldSatID = leftSatImage ? leftSatImage.reportID.replace(/-/g, "") : "";
    /** Remove Mapbox source and layer for previous satellite image */
    if (oldSatID != "" && beforeMap.current.getSource(oldSatID)) {
      beforeMap.current.removeLayer(oldSatID);
      beforeMap.current.removeSource(oldSatID);
      setLeftSatImage(null);
    }
  };

  /**
   * Removes the right satellite image from being display on the map
   *
   * */
  const removeRightSat = () => {
    /** Previous satellite image ID */
    let oldSatID = rightSatImage ? rightSatImage.reportID.replace(/-/g, "") : "";

    /** Remove Mapbox source and layer for previous satellite image */
    if (oldSatID != "" && afterMap.current.getSource(oldSatID)) {
      afterMap.current.removeLayer(oldSatID);
      afterMap.current.removeSource(oldSatID);
      setRightSatImage(null);
    }
  };

  // NDVI IMAGES

  /**
   * NDVI/TRUE COLOUR toggle handler, displays NDVI layer if false, removes NDVI layer if true
   *
   */
  useEffect(() => {
    if (trueColour) {
      removeLeftNDVI();
      removeRightNDVI();
    } else {
      if (leftSatImage) fetchNDVI(leftSatImage.reportID, "left");
      if (rightSatImage) fetchNDVI(rightSatImage.reportID, "right");
    }
  }, [trueColour]);

  /**
   * Fetches NDVI ID from corresponding satellite reportID and sets the left/right NDVI map layers with the corresponding NDVI image
   *
   * */
  const fetchNDVI = async (reportID, side) => {
    const NDVIReportID = await getNDVIFromSatellite(reportID);
    if (NDVIReportID != null) {
      if (side === "left") {
        if (ndviLeft != NDVIReportID.reportID) {
          setNdviLeft(NDVIReportID.reportID);
        } else {
          changeLeftNDVI();
        }
      } else if (side === "right") {
        if (ndviRight != NDVIReportID.reportID) {
          setNdviRight(NDVIReportID.reportID);
        } else {
          changeRightNDVI();
        }
      }
    } else {
      triggerNDVICreation(side)
    }
  };

  // Helper for fetchNDVI() that downloads the NDVI image if needed
  async function triggerNDVICreation(side) {
    setDownloadStatus("pending");
    let satelliteImage;
    if (side === "left") {
      satelliteImage = leftSatImage;
    } else if (side = "right") {
      satelliteImage = rightSatImage;
    }

    // edge case when both left and right sat images are the same
    let download = true;
    if (leftSatImage == rightSatImage && leftSatImage != null && side == "right")
      download = false;

    if (download) {
      let ndviData = await createNDVI(
        params.projectID,
        params.propertyID,
        satelliteImage.reportID,
        satelliteImage.name + "_ndvi"
      );
      if (ndviData !== null) {
        setDownloadStatus("success");
      } else {
        setDownloadStatus("failed");
      }
    }
  }

  /**
   * When ndviLeft is set, sets the left NDVI map layer with the corresponding NDVI image
   * When ndviRight is set, sets the right NDVI map layer with the corresponding NDVI image
   * */
  useEffect(() => {
    // isMountedLeft prevents code from running on first render
    if (isMountedLeft.current) {
      changeLeftNDVI();
    } else {
      isMountedLeft.current = true;
    }
  }, [ndviLeft]);

  useEffect(() => {
    // isMountedRight prevents code from running on first render
    if (isMountedRight.current) {
      changeRightNDVI();
    } else {
      isMountedRight.current = true;
    }
  }, [ndviRight]);

  /**
   *  Adds NDVI image layer to left map
   *
   * */
  const changeLeftNDVI = () => {
    let newSatID = "";
    if (ndviLeft != null) {
      newSatID = ndviLeft.replace(/-/g, "");
    }
    removeLeftNDVI();
    if (!beforeMap.current.getSource(newSatID)) {
      beforeMap.current.addSource(newSatID, {
        type: "raster",
        url: "mapbox://korotu-conroy-trinh." + newSatID,
      });
      beforeMap.current.addLayer({
        id: newSatID,
        source: newSatID,
        type: "raster",
      });
    }
    setOldNDVILeft(newSatID);
    movePropertiesToTop(beforeMap.current);
  };

  /**
   *  Adds NDVI image layer to right map
   *
   * */
  const changeRightNDVI = () => {
    let newSatID = "";
    if (ndviRight != null) {
      newSatID = ndviRight.replace(/-/g, "");
    }
    removeRightNDVI();
    if (!afterMap.current.getSource(newSatID)) {
      afterMap.current.addSource(newSatID, {
        type: "raster",
        url: "mapbox://korotu-conroy-trinh." + newSatID,
      });
      afterMap.current.addLayer({
        id: newSatID,
        source: newSatID,
        type: "raster",
      });
    }
    setOldNDVIRight(newSatID);
    movePropertiesToTop(afterMap.current);
  };

  /**
   * Removes the NDVI layer from the left map
   *
   * */
  function removeLeftNDVI() {
    if (beforeMap.current.getLayer(oldNDVILeft)) {
      beforeMap.current.removeLayer(oldNDVILeft);
      beforeMap.current.removeSource(oldNDVILeft);
      setOldNDVILeft(null);
    }
  }

  /**
   * Removes the NDVI layer from the right map
   *
   * */
  function removeRightNDVI() {
    if (afterMap.current.getLayer(oldNDVIRight)) {
      afterMap.current.removeLayer(oldNDVIRight);
      afterMap.current.removeSource(oldNDVIRight);
      setOldNDVIRight(null);
    }
  }

  /**
   * This makes sure NDVI is fetched AFTER a satellite reportID is provided
   *
   */
  useEffect(() => {
    if (!trueColour) {
      if (leftSatImage != null) {
        fetchNDVI(leftSatImage.reportID, "left");
      } else {
        removeLeftNDVI();
      }
    }
  }, [leftSatImage]);

  useEffect(() => {
    if (!trueColour) {
      if (rightSatImage != null) {
        fetchNDVI(rightSatImage.reportID, "right");
      } else {
        removeRightNDVI();
      }
    }
  }, [rightSatImage]);

  /** PROPERTY BOUNDARIES */

  /**
   * Once the component mounts, fetch landowners
   *
   */
  useEffect(() => {
    const loadLandowners = () => {
      getLandowners(params.projectID).then((allLandowners) => {
        setLandowners(allLandowners);
        setLoadedLandowners(true);
      });
    };

    loadLandowners();
  }, []);

  /**
   * Once the landowners are loaded, fetch all of their properties
   *
   */
  useEffect(() => {
    const loadProperties = async () => {
      // getting the properties
      if (landowners) {
        let properties = [];
        for (let i = 0; i < landowners.length; i++) {
          let curr = landowners[i];
          let currProperties = await getPropertiesForLandowner(curr.profileID);
          properties = properties.concat(currProperties);
        }
        setRawProperties(properties);
      }
    };

    loadProperties();
  }, [loadedLandowners]);

  /**
   * Once the properties are fetched, obtain the property coordinates (parsedProperties)
   *
   * */
  useEffect(() => {
    let properties = [];
    for (let i = 0; i < rawProperties.length; i++) {
      const currProperty = rawProperties[i];
      const regionInfo = getDataForRegion(currProperty.region);
      properties.push(regionInfo);
    }

    Promise.all(properties).then((res) => {
      setParsedProperties(res);
    });
  }, [rawProperties]);

  /**
   * Once property coordinates are loaded, display or hide property boundaries on map depending on showBoundaries flag
   *
   * */
  useEffect(() => {
    if (showBoundaries) {
      generateBoundaries(beforeMap.current, parsedProperties);
      generateBoundaries(afterMap.current, parsedProperties);
    } else {
      removeBoundaries(beforeMap.current, parsedProperties);
      removeBoundaries(afterMap.current, parsedProperties);
    }
  }, [parsedProperties, showBoundaries]);

  /** 
   * Reload boundaries when user selects a different field 
   * 
   * */
  useEffect(() => {
    removeBoundaries(beforeMap.current, parsedProperties);
    removeBoundaries(afterMap.current, parsedProperties);
    generateBoundaries(beforeMap.current, parsedProperties);
    generateBoundaries(afterMap.current, parsedProperties);
  }, [selectedField])

  // Helper functions for property boundaries

  /**
   * Generates the boundary layers for the properties
   * @param {*} map
   * @param {*} customPreloadedFeatures
   */
  function generateBoundaries(map, customPreloadedFeatures) {
    if (customPreloadedFeatures) {
      let neutralPropIDs = [];
      let highlightedPropIDs = [];
      let selectedPropIDs = [];
      let outlinePropIDs = [];
      customPreloadedFeatures.forEach((feature) => {
        createBoundaryLayers(map, feature, neutralPropIDs, outlinePropIDs, highlightedPropIDs, selectedPropIDs);

        const sourceID = map.getLayer(`${feature.data.id}-outline`).source;
        const source = map.getSource(sourceID);
        const type = source._data.geometry.type;
        const boundaryCoords =
          type === "MultiPolygon"
            ? source._data.geometry.coordinates[0]
            : source._data.geometry.coordinates;
        const center = polylabel(boundaryCoords, 1.0);

        createPopup(map, feature, new LngLat(center[0], center[1]), source);

        map.on("click", feature.data.id, (e) => {
          map.getCanvas().style.cursor = "pointer";
          map.flyTo({
            center: [center[0], center[1]],
            speed: 0.6,
            zoom: 15,
          });
        });
      });

      setNeutralIDs(neutralPropIDs);
      setOutlineIDs(outlinePropIDs);
      setHighlightedIDs(highlightedPropIDs);
      setSelectedIDs(selectedPropIDs);
    }
  }

  /** Helper for generateBoundaries() that creates the mapbox layers for the property boundaries */
  function createBoundaryLayers(map, feature, neutralPropIDs, outlinePropIDs, highlightedPropIDs, selectedPropIDs) {

    // Helper that fetches the boundary color
    const getBoundaryColor = (field, opacity) => {
      if (field == selectedField) return `rgba(7, 3, 255, ${opacity})`;
      return `rgba(102, 178, 255, ${opacity})`;
    };

    // Helper that fetches the boundary thickness
    const getBoundaryThickness = (field) => {
      if (field == selectedField) return 4;
      return 2;
    }

    if (!map.getSource(feature.data.id)) {
      map.addSource(feature.data.id, {
        type: "geojson",
        data: feature.data,
      });
      map.addLayer({
        id: feature.data.id,
        type: "fill",
        source: feature.data.id,
        paint: {
          "fill-outline-color": getBoundaryColor(feature.name, 1.0),
          "fill-color": getBoundaryColor(feature.name, 0.0),
        },
      });
    }
    neutralPropIDs.push(feature.data.id);

    map.addLayer({
      id: `${feature.data.id}-outline`,
      type: "line",
      source: feature.data.id,
      paint: {
        "line-color": getBoundaryColor(feature.name, 1.0),
        "line-width": getBoundaryThickness(feature.name),
      },
    });
    outlinePropIDs.push(`${feature.data.id}-outline`);

    map.addLayer({
      id: `${feature.data.id}-highlighted`,
      type: "fill",
      source: feature.data.id,
      layout: {},

      paint: {
        "fill-outline-color": getBoundaryColor(feature.name, 1.0),
        "fill-color": getBoundaryColor(feature.name, 0.4),
      },
      filter: ["in", "ID", ""],
    });
    highlightedPropIDs.push(`${feature.data.id}-highlighted`);

    map.addLayer({
      id: `${feature.data.id}-selected`,
      type: "fill",
      source: feature.data.id,

      paint: {
        "fill-outline-color": getBoundaryColor(feature.name, 1.0),
        "fill-color": getBoundaryColor(feature.name, 0.7),
      },
      filter: ["in", "ID", ""] /** false by default */,
    });
    selectedPropIDs.push(`${feature.data.id}-selected`);

    map.on("mousemove", feature.data.id, function (e) {
      map.getCanvas().style.cursor = "pointer";

      let feature = e.features[0];

      map.setFilter(`${feature.layer.id}-highlighted`, [
        "in",
        "ID",
        feature.properties.ID,
      ]);
    });

    // TODO click functionality
    // map.on('click', feature.data.id, function (e) {
    //   map.getCanvas().style.cursor = 'pointer';
    //   let feature = e.features[0];
    //   map.setFilter(`${feature.layer.id}-selected`, [
    //     'in',
    //     'ID',
    //     feature.properties.ID
    //   ]);
    //   props.setRegionID(feature.properties.ID);
    // });
    map.on("mouseleave", feature.data.id, function () {
      map.getCanvas().style.cursor = "";
      map.setFilter(`${feature.data.id}-highlighted`, ["in", "ID", ""]);
    });
  }

  /** Helper for generateBoundaries() that creates popups for all the properties on the map */
  function createPopup(map, feature, center, mapSource) {
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.on("mouseenter", `${feature.data.id}-highlighted`, (e) => {
      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = "pointer";

      const propName = mapSource._data.name;
      const landownerName = mapSource._data.landowner;

      const tooltip = `
            <div style="pointer-events: none;">
              <span>
                <strong>Property Name:</strong>
                ${propName}
              </span>
              <br />
              <span>
                <strong>Landowner:</strong>
                ${landownerName}
              </span>
            </div>`;
      // Populate the popup and set its coordinates
      // based on the feature found.
      popup.setLngLat(center).setHTML(tooltip).addTo(map);
      // popup.setHTML(tooltip).addTo(map);
    });

    map.on("mouseleave", `${feature.data.id}-highlighted`, () => {
      map.getCanvas().style.cursor = "";
      popup.remove();
    });
  }

  /** Removes property boundaries from map */
  function removeBoundaries(map, customPreloadedFeatures) {
    customPreloadedFeatures.forEach((feature) => {
      if (map.getLayer(feature.data.id)) {
        map.removeLayer(feature.data.id);
      }
      if (map.getLayer(`${feature.data.id}-outline`)) {
        map.removeLayer(`${feature.data.id}-outline`);
      }
      if (map.getLayer(`${feature.data.id}-highlighted`)) {
        map.removeLayer(`${feature.data.id}-highlighted`);
      }
      if (map.getLayer(`${feature.data.id}-selected`)) {
        map.removeLayer(`${feature.data.id}-selected`);
      }
      if (map.getSource(feature.data.id)) {
        map.removeSource(feature.data.id);
      }
      setNeutralIDs([]);
      setOutlineIDs([]);
      setHighlightedIDs([]);
      setSelectedIDs([]);
    });
  }

  /** Displays property boundary layers as the top layers on map */
  const movePropertiesToTop = (map) => {
    const moveLayersToTop = (map, ids) => {
      ids.forEach((id) => {
        map.moveLayer(id);
      });
    };

    moveLayersToTop(map, neutralIDs);
    moveLayersToTop(map, outlineIDs);
    moveLayersToTop(map, highlightedIDs);
    moveLayersToTop(map, selectedIDs);
  };

  /** LINK FROM MOWING DISPLAY CHART */

  /** If user is coming from mowing display page chart, set the relevant state variables to the values passed from the link 
  */
  useEffect(() => {
    if (
      location.state &&
      location.pathname ==
        `/project/${params.projectID}/property/${params.propertyID}/manual-detection`
    ) {
      setClickedFromChart(true);
      if (location.state.centroid) {
        setLong(location.state.centroid[0]);
        setLat(location.state.centroid[1]);
      }
      setSelectedField(location.state.field)
    }
  }, [location]);

  /** Once clickedFromChart is set to true, center the map on the centroid of the clicked NDVI image
   * but only if the centroid has been computed and is not undefined
   */
  useEffect(() => {
    if (clickedFromChart && location.state.centroid) {
      beforeMap.current.flyTo({
        center: [long, lat],
        zoom: 13.5,
      });
      afterMap.current.flyTo({
        center: [long, lat],
        zoom: 13.5,
      });
      // compare.current.setSlider(0);
    }
  }, [clickedFromChart]);

  /** Once satIDFromChart is set to true, set both the left and right satellite images to the clicked NDVI image */
  useEffect(() => {
    if (satIDFromChart != null && satelliteImages) {
      changeLeftSat(satIDFromChart);
      changeRightSat(satIDFromChart);
    }
  }, [satIDFromChart, satelliteImages]);

  /** HTML RENDERING */

  /** checking valid state */
  if (projectFailed) {
    return <Redirect to="/project" />;
  }

  /** renders the webpage components */
  return (
    <div className="page-margins ndvi-map-compare__content-wrapper">
      <div className="manual-change-detection__page__header">
        <h3>Manual Change Detection</h3>
        {
          clickedFromChart &&
          <div className="field-display">
            Field:
            <select className="field-display-dropdown"
                    value={selectedField}
                    onChange={(event) => setSelectedField(event.target.value)}>
              {parsedProperties.map((property) => (<option value={property.name}>{property.name}</option>))}
            </select>
          </div>
        }
      </div>
      {/** ##### REGION and PROJECT Details ##### */}
      {/* <h3 className="manual-change-detection__sub-heading">
        Project: {props.project ? props.project.name : ""}
      </h3> */}

      {downloadStatus === "failed" && (
        <h3 className="processing-text">
          {" "}
          Oh no! Something went wrong, please try again later
        </h3>
      )}
      {downloadStatus === "pending" && (
        <h3 className="processing-text">
          {" "}
          The download you triggered is processing
        </h3>
      )}
      {downloadStatus === "success" && (
        <h3 className="processing-text">
          {" "}
          Your NDVI has finished downloading! Please reload the page.
        </h3>
      )}

      <div className="manual-change-detection__selection">
        {/** ##### LEFT SATELLITE IMAGE ##### */}
        <div className="manual-change-detection__select">
          <div className="manual-change-detection__select-subcontainer">
          {console.log("here is a list for satelliteImages:", satelliteImages)}
          {console.log("this is leftSatImage at the start: ", leftSatImage)}
            {satelliteImages && satelliteImages.length > 0 ? (
              <DropdownSearch
                value={
                  leftSatImage
                    ? leftSatImage.reportID
                    : satIDFromChart
                      ? satIDFromChart
                      : ""
                }
                setValue={changeLeftSat}
                removeValue={removeLeftSat}
                objectList={satelliteImages ? satelliteImages : []}
                valueProperty="reportID"
                nameProperty="name"
                dateProperty="image_date"
                availableImagesProperty={numAvailableImages}
                sortProperty={false}
                onDisplayChange={dropdownDisplay}
                width="100%"
              />
            ) : (
              <div className="loading-container">
                <p>Loading...</p>
              </div>
            )}
            <button className="manual-change-detection__download-button">
              <img src={DownloadIcon} onClick={()=> downloadHandler("left")}/>
            </button>
          </div>
        </div>

        {/** ##### RIGHT SATELLITE IMAGE ##### */}
        <div className="manual-change-detection__select">
          <div className="manual-change-detection__select-subcontainer">
            {satelliteImages && satelliteImages.length > 0 ? (
              <DropdownSearch
                value={
                  rightSatImage
                    ? rightSatImage.reportID
                    : satIDFromChart
                      ? satIDFromChart
                      : ""
                }
                setValue={changeRightSat}
                removeValue={removeRightSat}
                objectList={satelliteImages ? satelliteImages : []}
                valueProperty="reportID"
                nameProperty="name"
                dateProperty="image_date"
                availableImagesProperty={numAvailableImages}
                sortProperty={false}
                onDisplayChange={dropdownDisplay}
                width="100%"
              />
            ) : (
              <div className="loading-container">
                <p>Loading...</p>
              </div>
            )}
            <button className="manual-change-detection__download-button">
              <img src={DownloadIcon} onClick={()=> downloadHandler("right")}/>
            </button>
          </div>
        </div>
      </div>

      <MapboxCompareSlider
        // Project info
        projectName={props.project ? props.project.name : "Project name"}
        projectDescription={props.project ? props.project.description : "Project description"}
        // Mapbox centered on PEI
        longitude={-63.14}
        latitude={46.25}
        zoom={10}
        clickedFromChart={clickedFromChart}
        // Before
        beforeRaster={
          leftSatImage ? leftSatImage.reportID.replace(/-/g, "") : ""
        }
        beforeStyle={"mapbox://styles/mapbox/satellite-v9"}
        beforeMap={beforeMap}
        // After
        afterRaster={
          rightSatImage ? rightSatImage.reportID.replace(/-/g, "") : ""
        }
        afterStyle={"mapbox://styles/mapbox/satellite-v9"}
        afterMap={afterMap}
        // Compare (slider) componenet
        compare={compare}
        //NDVI & True Colour Toggle
        trueColour={trueColour}
        trueColourToggle={
          trueColour ? () => setTrueColour(false) : () => setTrueColour(true)
        }
        //Property Boundary Toggle
        showBoundaries={showBoundaries}
        propertyBoundaryToggle={
          showBoundaries
            ? () => setShowBoundaries(false)
            : () => setShowBoundaries(true)
        }
      />
      <br />
    </div>
  );
}

function DropdownComponent(props) {
  const { satImageID, setValue, removeValue, satelliteImages, defaultVal } =
    props;
  return (
    <DropdownSearch
      value={satImageID}
      setValue={setValue}
      removeValue={removeValue}
      objectList={satelliteImages}
      valueProperty="reportID"
      nameProperty="name"
      dateProperty="image_date"
      onDisplayChange="image_date"
      width={316}
      height={32}
      defaultVal={defaultVal}
      // style={{height: "2rem", width: "19.75rem"}}
    />
  );
}
