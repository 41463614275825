import { url, VALUATION_PORT } from "./urlconfig";
import useFetch from "./useFetch";
import { MakeInitialReport, ChangeToFailed } from "./accounts";

/** Satellite Downloads and Interations API
 * 
*/

/** Creates or updates a report, depending on the update parameter */
export async function CreateMowingReport(reportInfo) {
    let reportID = reportInfo.reportID;

    if (reportInfo.checkForHaze) {
        const reportType = 'MowingDetection'
        reportID = await MakeInitialReport(
            reportInfo.name,
            reportInfo.projectID,
            reportInfo.startDate,
            reportInfo.endDate,
            reportInfo.spatialResolution,
            reportInfo.cloudMask,
            reportType)
        if (reportID === null) {
            return null;
        }
    }

    const path = "/mowing/";
    let body = ({
            'name': reportInfo.name,
            'project_id': reportInfo.projectID,
            'report_id': reportID,
            'start_date': reportInfo.startDate,
            'end_date': reportInfo.endDate,
            'spatial_res': reportInfo.spatialResolution,
            'cloud_mask': reportInfo.cloudMask,
            'email': reportInfo.email,
            'update': reportInfo.update, 
            'version': reportInfo.version,
            'check_for_haze': reportInfo.checkForHaze
    });

    if (reportInfo.update) {
        body['id_to_update'] = reportInfo.oldReportID;
    }
    
    if (!reportInfo.checkForHaze) {
        body['all_images'] = reportInfo.allImages;
        body['excluded_images'] = reportInfo.excludedImages;
    }

    // FETCH
    const response = await useFetch(url + ":" + VALUATION_PORT + path, 'POST', body, true);
    console.log(response);

    let output;

    if (response.hasError == false) {
        console.log(response);
        output = reportID;

    } else {
        console.error('Attempt to queue mowing report failed');
        console.error('Setting report_info to "failed"');
        console.error(response.errorMessage);

        // if changing report_info to "failed" fails, an error log will be generated
        await ChangeToFailed(reportInfo.mowingID);
        output = null;
    }
    console.log(output);
    return output;
}

export async function terminateGeneratingReport(reportID) {
    const path = "/mowing/";
    const body = ({'reportID': reportID});
    console.log("terminating")
    const response = await useFetch(url + ":" + VALUATION_PORT + path, 'DELETE', body, true);
    console.log(response)
  }

export async function getGeneratingReportNames() {
    const path = "/mowing/";
    console.log("Getting generating mowing report names.")
    const response = await useFetch(url + ":" + VALUATION_PORT + path, 'GET', null, true);
    console.log(response)

    if (response.hasError == false) {
        return response.data['generating_report_names']
    } else {
        console.error("Attempt to get currently-generating report names failed.")
        return null
    }
}